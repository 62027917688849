/*jshint esversion: 6 */

//Fuznioni per visualizzare i survey
import global from "../../global.js";
import * as partecipa_storage from './partecipa_storage.js';
import * as dbmng_ext from '../dbmng_ext.js';
import photo from '../../core/photo.js';
import t from '../../translate.js';

import Simple from './custom/template_simple.js';
import Mosca from './custom/Mosca.js';
import Sostenibilita from './custom/Sostenibilita.js';

//La funzione crea il render e l'interact di una singola sezione
//export function drawSpecificSection(id_farm, survey_schemas,id_survey_schema,step){
export function drawSpecificSection(section, survey, scores, id_farm, id_survey_schema){
  //Stampa l'html del Survey
  renderSurveyPage(section, survey, id_farm, id_survey_schema);



  try{
      //Riempie le sezioni
      if (typeof section.dbmng2!='undefined') {
        drawDbmng(section, survey);
      }
      // debugger
      if (typeof section.dbmng2_form_inline!='undefined') {
        drawDbmngInline(section, survey);
      }

      if (typeof section.photo!='undefined' && section.photo) {
        fillPhotos(survey);
      }

      //Inizi della sezione di interact
      var custom=getCustomClass(section, id_farm, id_survey_schema);
      if(custom!=null){
        custom.interact(survey);
      }
      else if (id_survey_schema==98) {
        interactFenologia(survey, id_farm, id_survey_schema);
      }
    }
    catch(e){
      console.log("a");
      alert(e);
    }
}

//Preparre l'html delle immagini
function getPhotoHtml(guid_rilievo){
  var html='';
  html+="<div id='photo'>";
    html+='<p class="row">';
      if (!global.is_cordova()) {
        if (global.nomeApp=='Agro Abruzzo') {
          html+=`
          <div class="list no-hairlines-md">
            <ul>
              <li class="item-content item-input">
                <div class="item-inner">
                  <div class="item-title item-label">Risoluzione immagine</div>
                  <div class="item-input-wrap input-dropdown-wrap">
                    <select id="risoluzione_immagine" placeholder="Scegli la risoluzione...">
                      <option value="bassa" selected>Bassa risoluzione</option>
                      <option value="alta">Alta risoluzione</option>
                    </select>
                  </div>
                </div>
              </li>
            </ul>
          </div>
          `;

          html+='<input style="display:none;" id="scatta_foto" class="scatta_foto col" type="file" accept="image/*"></input>';
          html+='<button id="scatta_foto_btn" class="scatta_foto col button button-large button-raised" type="file" accept="image/*"><span>Aggiungi</span> <i class="icon f7-icons">camera_fill</i></button>';
        }
        else {
          html+='<input style="display:none;" id="scatta_foto" class="scatta_foto col" type="file" accept="image/*,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"></input>';
          html+=`<button id="scatta_foto_btn" class="scatta_foto col button button-large button-raised" type="file" accept="image/*,.pdf,.csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"><span>${global.uploadFile?'Carica documenti ':'Aggiungi'}</span> <i class="icon f7-icons">${global.uploadFile?'arrow_up_doc':'camera_fill'}</i></button>`;
        }

      }
      else {
        html+=`<button id="scatta_foto" class="scatta_foto col button button-large button-raised" type="file" accept="image/*"><span>${global.uploadFile?t('Carica documenti'):t('Aggiungi')}</span> <i class="icon f7-icons">${global.uploadFile?'arrow_up_doc':'camera_fill'}</i></button>`;
      }
    html+='</p>';

    try {
      html+=photo.getPhotoDiv(guid_rilievo);
    }
    catch (e) {
      global.crash_log(e,"photo.getPhotoDiv(guid_rilievo) -");
    }
  html+="</div>";
  return html;
}

//Prende l'immagine
function fillPhotos(survey){
  var guid_rilievo=survey.guid;
  photo.fillPhotoDiv(guid_rilievo);

  if (global.is_cordova()) {
    jQuery("#scatta_foto").click(function() {
      photo.takePicture(guid_rilievo);
    });

  }
  else {


    jQuery("#scatta_foto").change(function() {
      photo.takePicture(guid_rilievo);
    });

    jQuery('#scatta_foto_btn').click(function(){
      jQuery("#scatta_foto").click();
    });
  }
}

//Classe custom personalizzata, se esiste la instanzia (altrimeni usa Simple)
function getCustomClass(section, id_farm, id_survey_schema){
  var custom=null;
  if(typeof section.custom !='undefined'){
    try{
      if (section.custom=='Mosca') {
        custom=new Mosca(id_farm, id_survey_schema, section);
      }
      else if (section.custom=='Sostenibilita') {
        custom=new Sostenibilita(id_farm, id_survey_schema, section);
      }
      else {
        custom=new Simple(id_farm, id_survey_schema, section);
      }
    }
    catch(e){
      console.error(e);
    }
  }
  return custom;
}

//La funzione stampa il layout del Survey
function renderSurveyPage(section, survey, id_farm, id_survey_schema){
  //INIZIO del Render della pagina
  var body='';

  var title='';
  title+="<div class='central col-95'>";
    title+=section.title;
  title+="</div>";
  title+="<div class='col-5'>";
    title+="<div id='localizationIcon'></div>";
  title+="</div>";
  body+="<div id='section_body'>";
    body+=section.body;
  body+="</div>";

  var custom=getCustomClass(section, id_farm, id_survey_schema);


  if (typeof section.dbmng2_form_inline!='undefined') {
    console.log(section.dbmng2_form_inline);
    body+="<div id='dbmng_form_inline'>";
    body+="</div>";
  }
  //INIZIO della Funzione di render
  if(custom!==null){
    body+=custom.render(survey);
  }
  else if (typeof section.dbmng2!='undefined') {
    console.log(section.dbmng2);
    body+="<div id='dbmng_form'>";
    body+="</div>";
  }
  else if(typeof section.action !='undefined'){
    //Se la section è di tipo select mostra i pulsanti su cui scegliere
    if(section.action.type=='select'){

      body+='<div class="list media-list">';
        body+='<ul id="pheno_images" class="row">';
        section.action.buttons.map(function(but){
          body+='<li class="col-50">';
          console.log(but);
            body+='<img desc="'+but.label+'" id="'+but.value+'" class="fenoimg not_selected_img" src="'+but.image+'" width="160"/>';
          body+='</li>';
        });
        body+='</ul>';
      body+="</div>";
    }
  }
  else { //sezione sono descrittiva
  }

  //Fine della sezione di render
  var html='';
  html+='<div class="central">';
  html+=body;
  html+='</div>';

  var guid_rilievo=survey.guid;



  var exclude_card=false;
  if (section.exclude_card) { //Vecchio colide da eliminare || step==0 ||  section.custom=='Sostenibilita' || (id_survey_schema==99 && step==0) || (id_survey_schema==5 && step==0
    exclude_card=true;
  }

  if (typeof section.photo!='undefined' && section.photo) {
    if (exclude_card) {
      html+='<div class="card"><div class="card-content card-content-padding">';
    }
    html+=getPhotoHtml(guid_rilievo);
    if (exclude_card) {
      html+='</div></div>';
    }
  }

  var title_vero=title;
  var page={
    title: title_vero,
    content: html,
    exclude_card:exclude_card
  };
  global.theme.render(page);

  //FINE del render
}

//Stampa il form DBMNG2 nella sezione
function drawDbmng(section, survey){
  console.log(section.dbmng2);

  var fields = (section.dbmng2);
  var aForm = {};
  aForm.table_name = "klean";
  aForm.primary_key = Array('id');
  aForm.fields = fields;

  //TODO farlo anche su marketing
  var selectNMWidgetF7=dbmng_ext.extendSelectNMWidgetF7();
  jQuery.each(aForm.fields,function(k,v){
    if (v.widget=='select_nm') {
      v.external_widget=selectNMWidgetF7;
    }
  });

  var theme_f7 = new Dbmng.Framework7Theme();

  var template=null;
  if (section.template) {
    template=section.template;
  }

  global.form=new Dbmng.Form({aForm:aForm, aParam:{exclude_card:true}, theme:theme_f7});
  jQuery('#dbmng_form').append(global.form.createForm(survey,template));

  jQuery('#f7.page-content')[0].scrollTop=0;

  if(section.function_pre){
    try{
      var debug=false;
      var f;
      if(debug){
        f=function(json){
          return partecipa_debug.testFunctionDebug();
        };
      }
      else{
        f = new Function('json', section.function_pre);
      }
      var result=f(survey);
      if(result.message){
        jQuery("#section_body").html('<div class="block block-strong">'+result.message+'</div>');
      }
    }
    catch(e){
      console.log(e);
      jQuery("#section_body").append('function_pre: Si è verificato un errore.');
    }
  }
}

//Stampa il form DBMNG2 nella sezione
function drawDbmngInline(section, survey){
  console.log(section.dbmng2_form_inline);

  var fields = (section.dbmng2_form_inline);
  
  jQuery.each(fields,function(kfield,field){
    var aForm = {};
    aForm.table_name = "klean";
    aForm.primary_key = Array('id');
    aForm.fields = field;
    var selectNMWidgetF7=dbmng_ext.extendSelectNMWidgetF7();
    jQuery.each(field,function(k,v){
      if (v.widget=='select_nm') {
        v.external_widget=selectNMWidgetF7;
      }
    });

    var theme_f7 = new Dbmng.Framework7Theme();

    var template=null;
    if (section.template) {
      template=section.template;
    }
    // var theme_boot=new Dbmng.AppTheme();
    if(!global.form_inline){
      global.form_inline={};
    }
    global.form_inline[kfield]=new Dbmng.FormInline({aForm:aForm, theme:theme_f7, aParam:{'del_label':'Canc','hide_label': true, 'do_insert': true, 'do_delete': true}});
    // console.log(survey[kfield]);
    jQuery('#dbmng_form_inline').append(global.form_inline[kfield].createForm(survey[kfield],template));
  });

  //TODO farlo anche su marketing

  // debugger

  jQuery('#f7.page-content')[0].scrollTop=0;
}


//Salva il dato della fenologia
function interactFenologia(survey, id_farm, id_survey_schema){
  jQuery(".not_selected_img").click(function() {
    var fase_fenologica=jQuery(this).attr('id');

    var partialSurvey={
      fase_fenologica: fase_fenologica,
      coordinates: global.coordinates
    };
    partecipa_storage.updatePartialSurvey(id_farm, id_survey_schema, survey.guid, partialSurvey);

    jQuery('.fenoimg').removeClass('selected_img').addClass('not_selected_img');
    jQuery('#'+fase_fenologica).removeClass('not_selected_img').addClass('selected_img');

    // Scroll to top
    jQuery('.ptr-preloader')[0].scrollIntoView();
    jQuery('#section_body').removeClass('pay_attention');
    setTimeout(function(){
      jQuery('#section_body').addClass('pay_attention');
    },100);
    jQuery('#section_body').html(jQuery('#'+fase_fenologica).attr('desc'));
  });
  jQuery('#'+survey.fase_fenologica).click();
}
