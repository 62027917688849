/*jshint esversion: 6 */
//Fuznioni per creare la home Page di partecipa
import global from "../../global.js";
import dataManagement from "./data.js";
import t from '../../translate.js';
import gps from '../../core/gps.js';
import * as tools from '../../tools.js';
import * as partecipa_storage from './partecipa_storage.js';


//Verifica se c'è un'azienda attiva; chiama poi home_partecipa con l'azienda attiva
export function home_page(){
  var s=partecipa_storage.getSettings();
  if(s.user){
    if (s.id_farm) {
      home_partecipa(s.id_farm);
    }
    //TODO s.res non prende il valore nemmeno dopo il set (seggiano 5 marzo 21), non sto capendo come mai
    else if (s.res && s.res.id_farm) {
      //Questa funzione serve sono per gli utenti che hanno il res vecchio
      //Eliminare dopo Giugno 2021
      if(s.res.id_farm.length>1){
        home_partecipa(s.res.id_farm[0]);
      }
      else{
        var id_farm=null;
        if (s.res.selected_id_farm) {
          id_farm=s.res.selected_id_farm;
        }
        home_partecipa(id_farm);
      }
    }
    else {
      home_partecipa();
    }
  }
  //se non esiste l'utente fa il login
  else {
    location.hash='login';
  }
}


// Costruisce la home page di partecipa partendo dall'id dell'azienda
export function home_partecipa(id_farm, data_type){

  var html='';
var optFields='';
  html+=`
        <div>
          <div class="block-title">Scegli il campo</div>
          <div class="list inset">
            <div id='partecipa_header'></div>
          </div>
      </div>
      `;
              // <li>
              //   <a id="new_phenotopic_survey" class="col button button-large button-raised button-fill link external" style="margin:5px;">Phenotopic survey</a>
              // </li>


  // html+="";


  var title='';
  title+="<div class='row'>";
    title+="<div class='col-95'>";
      title+=t("Monitoraggio partecipativo");
    title+="</div>";
    title+="<div class='col-5'>";
      title+="<div id='localizationIcon'><i class='icon f7-icons'>location</i></div>";
    title+="</div>";
  title+="</div>";
  html+=`
  <div class="card" id="mon_part">
    <div class="card-header">${title}</div>
    <div class="card-content card-content-padding" id='partecipa_content'>
    </div>
  </div>`;
  var page={
    title: '',
    content: html,
    exclude_card: true,
  };

  global.theme.render(page);

  if (global.nomeApp!='OliveRec' && global.nomeApp!='Tosquilat' && global.nomeApp!='FAIRshare' && global.nomeApp!='Olig+' && global.nomeApp!='Agro Abruzzo' && global.nomeApp!='AgroAmbiente Toscana') {
    html='';
    html+="<div id='banner'>";
      html+=tools.drawBanner();
    html+="</div>";
    jQuery('#f7_content').append(html);
  }

  var dm=new dataManagement();
  dm.getSurveySchema(id_farm)
  .then( (arg) => {

    var survey_schemas=arg.survey_schemas;
    var id_farm=arg.id_farm;

    var s=partecipa_storage.getSettings();
    // if (typeof id_farm=='undefined' && s.id_farm!='undefined') {
    //   id_farm=s.id_farm;
    // }
    if (global.nomeApp=='Agro Abruzzo') {
      homeAbruzzo(id_farm, survey_schemas,data_type);
    }
    else {
      drawSurveySchema(id_farm, survey_schemas,data_type);
    }
  })
  .catch( (err) => {
    console.log(err);
    alert("Err:"+err);
  });
}


//La funzione fa Partire il GPS nella home dei survey
//TODO Il call back si usa???
export function runGPS(callback){
  // global.app.dialog.preloader('Sto prendendo le coordinate...');
  console.log("GPS____run");

  ///segnala se a 20 secondo da adesso il GPS non ha trovato delle coordinate
  setTimeout(function(){
    if (typeof global.coordinates=='undefined') {
      // global.app.dialog.close();
      global.app.dialog.alert("C'è stato un problema nella rilevazione del dato GPS.","Errore GPS");
    }
  },20000);

  gps.startGPS({
    save_place: true, //dentro la libreria chiama il reverse geocode e lo salva in global.coordinates
    buffer_max_size: 2,
    position_temp: function(pos){
      console.log("GPS_temp",pos);
      jQuery('#localizationIcon').html('<i class="icon f7-icons color-purple">location</i>');

      //TODO la funzione tmporanea e permanente devono esere unite
      if (global.is_cordova() && typeof pos=='string') {
        pos=JSON.parse(pos);
      }
      pos.timestamp=new Date().getTime();
      global.coordinates=pos;
      if (typeof callback=='function') {
        console.log(pos);
        callback(pos);
      }
    },
    position: function(pos){
      console.log("GPS_final",pos);
      jQuery('#localizationIcon').html('<i class="f7-icons">location_fill</i>');

      if (global.is_cordova() && typeof pos=='string') {
        pos=JSON.parse(pos);
      }
      pos.timestamp=new Date().getTime();
      global.coordinates=pos;

      //TODO perche non chiama il callback?)
    }
  });
}


//La funzione mostra l'elenco dei SurveySchema per il monitoraggio
export function drawSurveySchema(id_farm, survey_schemas,data_type){
  var schemaHTML='';
  jQuery('#localizationIcon').html('<i class="icon f7-icons">location</i>');
  runGPS();

  var show_crops=false;
  var unique_crops=[];
  jQuery.each(survey_schemas,function(k,v){
    if( unique_crops.indexOf(v.crop_name)==-1 ){
      unique_crops.push(v.crop_name);
    }
  });
  if(unique_crops.length>1){
    show_crops=true;
  }


  schemaHTML+='<div class="row">';
  var old_crop="";
  jQuery.each(survey_schemas,function(k,v){

    //Show the header of the crops
    if(show_crops && old_crop!=v.crop_name){
      schemaHTML+="<h3>"+v.crop_name+"</h3>";
    }
    old_crop=v.crop_name;

    schemaHTML+='<div class="col-xs-6 col-md-3">';
      var surveys=partecipa_storage.selectSurveysStorage(id_farm, v.id_survey_schema);
      schemaHTML+='<div class="row">';
        schemaHTML+='<button onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'\'" id="'+v.id_survey_schema+'" class="col-80 button button-round button-outline button-large button-margin">'+v.name+'</button>';
        schemaHTML+='<i  onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'/new\'" style="font-size:50px;" class="f7-icons col-20">plus_circle_fill</i>';
      schemaHTML+='</div>';
    schemaHTML+='</div>';
  });

  schemaHTML+='</div>';

  jQuery('#partecipa_content').html(schemaHTML);

  jQuery('#footer').html('');
}



export function homeAbruzzo(id_farm, survey_schemas,data_type){

  //trova se ci sono dei campi
  var fields=partecipa_storage.selectFieldsStorage(id_farm);


  global.skipFinalizza=true;

  console.log(fields);
  // debugger
  // if(!fields || fields.length==0){

  //   let html=`
    
  //     <h3>Crea un nuovo campo!</h3>

  //   `;
  //   jQuery('#partecipa_header').html(html);

  // }
  // else{

    let select='';
    if(!fields || fields.length==0){
      select=`
      <div class="row" style="margin-top: 8px;">
        <div class="col-75" style="margin: auto;">
        Crea il primo campo!
        </div>
        <div class="col-25" style="margin: auto;">
          <button onclick="location.hash='${id_farm}/field_detail/new_field'" class="button button-fill button-round">+</button>
        </div>
      </div>
      `;
      jQuery('#mon_part').hide();
    }
    else{
      select=`
      <div class="row">
      <div class="col-75">
        <select id="select_field">
      `;
      jQuery.each(fields,function(k,v){ 
        select+=`<option data-id_crop="${v.id_crop}" value="${v.guid}">${v.name} - ${resolveCrop(v.id_crop)}</option>`;
      });
      select+=`</select></div>`;

      select+=`
          <div class="col-25" style="margin: auto;">
            <button onclick="location.hash='${id_farm}/field_detail/new_field'" class="button button-fill button-round">+</button>
          </div>
        </div>
      `;

    }

    let html=`
      <ul> 
        <li class="item-content item-input" id="fields_select">
          <div class="item-inner">
            <div class="item-input-wrap">
              ${select}
            </div>
          </div>
        </li>
      </ul>
    `;
    jQuery('#partecipa_header').html(html);



    jQuery("#select_field").on("change",function(){
      var id_field=jQuery(this).val();
      var id_crop=jQuery(this).find(":selected").data("id_crop");

      drawSurveySchemaAbruzzo(id_farm+"|"+id_field, survey_schemas,data_type, id_crop);
      partecipa_storage.saveLastFieldUsed(id_field);
    });

    var s=partecipa_storage.getSettings();
    if(s.lastFieldUsed){
      jQuery("#select_field").val(s.lastFieldUsed).change();
    }
    else{
      jQuery("#select_field").first().change();
    }

  // }

}

export function resolveCrop(id_crop){
  var s=partecipa_storage.getSettings();
  var crops=s.crop;
  var crop_name='';
  jQuery.each(crops,function(k,v){
    if(v.id_crop==id_crop){
      crop_name=v.crop_name;
    }
  });
  return crop_name;
}

//La funzione mostra l'elenco dei SurveySchema per il monitoraggio
export function drawSurveySchemaAbruzzo(id_farm, survey_schemas,data_type, id_crop){
  jQuery('#localizationIcon').html('<i class="icon f7-icons">location</i>');
  runGPS();

  var show_crops=false;
  var unique_crops=[];
  jQuery.each(survey_schemas,function(k,v){
    if(id_crop){
      if(v.id_crop==id_crop){
        if( unique_crops.indexOf(v.crop_name)==-1 ){
          unique_crops.push(v.crop_name);
        }
      }
    }
    else{
      if( unique_crops.indexOf(v.crop_name)==-1 ){
        unique_crops.push(v.crop_name);
      }
    }
  });
  if(unique_crops.length>1){
    show_crops=true;
  }

// debugger
  
  

    var schemaHTML='';
    schemaHTML+='<div class="row">';

    



    var old_crop="";

    var schede_olivo='';
    var schede_vite='';
    jQuery.each(survey_schemas,function(k,v){
      if(id_crop==v.id_crop){
        if (v.crop_name=='Olivo') {
          schede_olivo+='<div class="col-xs-6 col-md-3">';
            var surveys=partecipa_storage.selectSurveysStorage(id_farm, v.id_survey_schema);
            schede_olivo+='<div class="row">';
              schede_olivo+='<button onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'\'" id="'+v.id_survey_schema+'" class="col-80 button button-round button-outline button-large button-margin">'+v.name+'</button>';
              schede_olivo+='<i  onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'/new\'" style="font-size:50px;" class="f7-icons col-20">plus_circle_fill</i>';
            schede_olivo+='</div>';
          schede_olivo+='</div>';
        }

        if (v.crop_name=='Vite') {
          schede_vite+='<div class="col-xs-6 col-md-3">';
            var surveys=partecipa_storage.selectSurveysStorage(id_farm, v.id_survey_schema);
            schede_vite+='<div class="row">';
              schede_vite+='<button onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'\'" id="'+v.id_survey_schema+'" class="col-80 button button-round button-outline button-large button-margin">'+v.name+'</button>';
              schede_vite+='<i  onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'/new\'" style="font-size:50px;" class="f7-icons col-20">plus_circle_fill</i>';
            schede_vite+='</div>';
          schede_vite+='</div>';
        }
      }
    });


    var vite_collection=`
      <div class="accordion-item" id="vite_collection">
        <div class="col-xs-8 col-md-3">
          <div class="row">
            <button class="button button-fill button-round button-large button-margin accordion-item-toggle">
              <i class="icon icon-plus">+</i>
              <i class="icon icon-minus">-</i>
              Schede Vite
            </button>
          </div>
        </div>

        <div class="accordion-item-content">
          ${schede_vite}
        </div>
      </div>
    `;

    var olivo_collection=`
          <div class="accordion-item" id="olivo_collection">
        <div class="col-xs-8 col-md-3">
          <div class="row">
            <button class="button button-fill button-round button-large button-margin accordion-item-toggle">
              <i class="icon icon-plus">+</i>
              <i class="icon icon-minus">-</i>
              Schede Olivo
            </button>
          </div>
        </div>

        <div class="accordion-item-content">
          ${schede_olivo}
        </div>
      </div>
    `;

    schemaHTML+=`
    <div class="block accordion-list custom-accordion">
      ${schede_olivo!=''?olivo_collection:''}
      ${schede_vite!=''?vite_collection:''}
    </div>
    `;


    jQuery.each(survey_schemas,function(k,v){
      if (v.crop_name!='Olivo' && v.crop_name!='Vite') {
        //Show the header of the crops
        if(show_crops && old_crop!=v.crop_name){
          schemaHTML+="<h3>"+v.crop_name+"</h3>";
        }
        old_crop=v.crop_name;

        schemaHTML+='<div class="col-xs-6 col-md-3">';
          var surveys=partecipa_storage.selectSurveysStorage(id_farm, v.id_survey_schema);
          schemaHTML+='<div class="row">';
            schemaHTML+='<button onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'\'" id="'+v.id_survey_schema+'" class="col-80 button button-round button-outline button-large button-margin">'+v.name+'</button>';
            schemaHTML+='<i  onclick="location.hash=\''+id_farm+'/partecipa/'+v.id_survey_schema+'/new\'" style="font-size:50px;" class="f7-icons col-20">plus_circle_fill</i>';
          schemaHTML+='</div>';
        schemaHTML+='</div>';
      }
    });


    schemaHTML+='</div>';

    jQuery('#partecipa_content').html(schemaHTML);
  

  jQuery('#footer').html('');
}
