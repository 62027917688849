/*jshint esversion: 6 */
import global from "../../global.js";
import mapHelper from '../map.js';
import gps from '../gps.js';
import storage from "../../storage.js";
import t from '../../translate.js';
import * as tools from '../../tools.js';

import * as partecipa_home from './partecipa_new_home.js';
import * as partecipa_storage from './partecipa_storage.js';
import * as partecipa_draw from './partecipa_new_draw.js';

export function partecipa_tools(){

  var html='';
  html+='<div class="list links-list">';
    // html+='<ul>';
      html+='<li>';
        html+='<a class="link external icon-only" href="#show_all_fields" ><i class="f7-icons">map_fill</i><span>'+t('Visualizza i campi')+'</span></a>';
      html+='</li>';

      if (global.nomeApp=='Agro Abruzzo') {
        html+='<li>';
          html+='<a onClick="cleanLocalImages()" class="link external icon-only" ><i class="f7-icons">photo_fill_on_rectangle_fill</i><span>Elimina foto localmente</span></a>';
        html+='</li>';
      }
    // html+='</ul>';
  html+='</div>';

  jQuery('#appSettings').append(html);

  jQuery("#appSettings").change(function() {
    jQuery.each(jQuery("#appSettings input"),function(k,v){
      s[jQuery(v).attr('id')]=jQuery(v).val();
    });
    storage.save(s);
  });
}

export function cleanLocalImages(){
  var c=confirm("ATTENZIONE. Vuoi davvero eliminare le foto localmente? Non saranno più visibili all'interno dell'app, ma saranno visibili sul sito web.");
  if (c) {
    var s=partecipa_storage.getSettings();
    jQuery.each(s.my_images,function(kril,rilievo){
      jQuery.each(rilievo,function(ki,image){
        console.log(image);
        if (image.saved) {
          delete rilievo[ki];
        }

      });
    });
    storage.save(s);
    console.log("ELIMINA FOTO");
  }
}
window.cleanLocalImages=cleanLocalImages;

export function show_all_fields(){
  var s=partecipa_storage.getSettings();

  var fields=partecipa_storage.selectFieldsStorage(s.id_farm);


  var crops=partecipa_storage.getCrops();

  var html='';
  var title='';
  console.log(fields);
  if (fields.length>0) {
    title+="I tuoi campi";
    html+='<div class="list links-list">';
      html+='<ul>';
        jQuery.each(fields,function(k,v){
          html+='<li>';
            var crop_name=crops[v.id_crop];
            if(!crop_name){
              crop_name="Non definito";
            }

            var coord=" - <b style='color:#b71c1c'>Senza coordinate</b>";
            if(v.lat){
              coord="";
            }

            html+='<a class="link external" href="#field_detail/'+v.guid+'">'+v.name+' - '+crop_name+coord+'</a>';
          html+='</li>';
        });
      html+='</ul>';
    html+='</div>';
  }
  else {
    html+='<div class="block-title block-title-medium">Non hai ancora nessun campo.</div>';
  }

  global.theme.render({
    title: title,
    exclude_card:true,
    content: html
  });
}

export function show_field(guid_field, id_farm_center){
  var s=partecipa_storage.getSettings();

  var id_farm=s.id_farm;
  if (id_farm_center) {
    id_farm=id_farm_center;
  }
  var fields=partecipa_storage.selectFieldsStorage(id_farm);

  var field={};
  if(guid_field){
    jQuery.each(fields,function(k,v){
      if (v.guid==guid_field) {
        field=v;
      }
    });
  }
  else {
    field={
      guid:tools.getGuid(),
      name:'',
      lat:42.5,
      lon:13.5,
      sync:false,
      id_crop:null
    }
  }

  var html='';


  var map=new mapHelper();
  var runGPS=false;
  html+="<div id='agroMap'>"+map.getHtml(true,runGPS)+"</div>";

  html+='<div class="list no-hairlines-md">';
    html+='<ul>';
      html+='<li class="item-content item-input">';
        html+='<div class="item-media">';
          html+='<i class="icon demo-list-icon"></i>';
        html+='</div>';
        html+='<div class="item-inner">';
          html+='<div class="item-title item-label">Nome del campo</div>';
          html+='<div class="item-input-wrap">';
            html+='<input type="text" id="field_name" placeholder="Il nome del tuo campo" value="'+field.name+'" />';
            html+='<span class="input-clear-button"></span>';
          html+='</div>';
        html+='</div>';
      html+='</li>';
      if(!field.id_crop){
        var cropOpt='<option value="" selected disabled>Seleziona la coltura</option>';
        jQuery.each(s.crop,function(k,v){
          cropOpt+='<option value="'+v.id_crop+'">'+v.crop_name+'</option>';
        });
        

        html+=`
        <li class="item-content item-input">
          <div class="item-media">
            <i class="icon demo-list-icon"></i>
            </div>
            <div class="item-inner">  
              <div class="item-title item-label">Coltura</div>
            <div class="item-input-wrap">
              <select id="field_id_crop">${cropOpt}</select>
            </div>
          </div>
        </li>
            `;
      }
    html+='</ul>';
  html+='</div>';

  var color='red';
  var label='Campo non ancora sincronizzato';
  if (field.sync) {
    color='green';
    label='Campo già sincronizzato correttamente';
  }
  html+='<div class="block block-strong">';
    html+='<p>';
      html+='<span><i class="icon f7-icons color-'+color+'">largecircle_fill_circle</i> '+label+'</span>';
    html+='</p>';
  html+='</div>';

  global.theme.render({
    title: field.name,
    exclude_card:true,
    content: html
  });

  var zoom=15;
  if (field.lat==42.5) {
    zoom=7;
  }
  map.initMap([field.lat,field.lon],zoom, true, {});

  var button='';
  button+='<button id="salva_field" class="button button-large">Salva</button>';

  jQuery('#footer').html(button);


  jQuery("#salva_field").click(function() {
    if(guid_field){
      var toSave=false;
      if(jQuery("#manual_gps_latitude").length>0){
        if(jQuery("#manual_gps_latitude").val()!==''){
          toSave=true;

          field.lat=jQuery('#manual_gps_latitude').val();
          field.lon=jQuery('#manual_gps_longitude').val();
          field.provider='Manual';
        }
      }
      if (field.name!==jQuery('#field_name').val()) {
        toSave=true;
        field.name=jQuery('#field_name').val();
      }
      console.log(field);

      if (toSave) {
        var deep=false;
        partecipa_storage.updatePartialField(s.id_farm, field.guid, field, deep);
        location.hash='';
      }
      else {
        global.app.dialog.alert("Niente da salvare", "Attenzione", function(){location.hash='';});
      }
    }
    else {
      var toSave=false;
      if(jQuery("#manual_gps_latitude").length>0){
        if(jQuery("#manual_gps_latitude").val()!==''){
          toSave=true;

          field.lat=jQuery('#manual_gps_latitude').val();
          field.lon=jQuery('#manual_gps_longitude').val();
          field.provider='Manual';
        }
      }
      if (field.name!==jQuery('#field_name').val()) {
        toSave=true;
        field.name=jQuery('#field_name').val();
      }
      if (jQuery('#field_id_crop').val()) {
        toSave=true;
        field.id_crop=jQuery('#field_id_crop').val();
      }
      else {
        toSave=false;
      }
      console.log(field);
      if (toSave) {
        partecipa_storage.insertFieldStorage(s.id_farm, null, field);
        location.hash='partecipa';
      }
      else {
        global.app.dialog.alert("Compila tutti i campi per salvare", "Attenzione");
      }
    }

  });



  jQuery("#manual-Map-checkbox input").change(function() {
    jQuery('#manual-Map-checkbox').css('opacity','0.5');
    jQuery(this).attr("disabled", true);
    gps.manualMap();
  });

}
