/*jshint esversion: 6 */

import global from '../global.js';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

import 'leaflet-fullscreen/dist/Leaflet.fullscreen.js';
import 'leaflet-fullscreen/dist/leaflet.fullscreen.css';

import 'fullscreen-api-polyfill/fullscreen-api-polyfill.js';

//Attenzione va sistemato bene!

class mapHelper {

  constructor(opt) {
    // INSERIRE LA BASE_CALL NEL RUN DEL PROGETTO

  }

  /*

  Crea un widget con una mappa e con i due pulsanti manuelaGPS
  Contiene dei campi hidden da cui è possibile prendere le coordinate

  Modalità:
    external_gps: il GPS è esterno e si èuò solo modificare (come survey) il Tasto GPS è disabilitato
    active_gps: la mappa attiva da subito il funzionamento del GPS (come fitosirt)


    map = new map({mode:external:gps, defaultCenter:[], defaultZoom:16, location:[43,11]});




  */


  initMap(defaultCenter, defaultZoom, markerLocation, opt){
    // debugger
    //if(typeof global.map!=='undefined'){alert("Global map exists");}

    if(!defaultZoom){
      defaultZoom=16;
    }

    // debugger
    var map = L.map((opt.id_div?opt.id_div:'map_container'), opt);

    var toner=false;
    if(opt && opt.toner){
      toner=opt.toner;
    }
    if(toner){
      var Stamen_Toner = L.tileLayer('//stamen-tiles-{s}.a.ssl.fastly.net/toner/{z}/{x}/{y}{r}.{ext}', {
        attribution: 'Map tiles by <a href="http://stamen.com">Stamen Design</a>, <a href="http://creativecommons.org/licenses/by/3.0">CC BY 3.0</a> &mdash; Map data &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
        subdomains: 'abcd',
        minZoom: 0,
        maxZoom: 20,
        ext: 'png'
      }).addTo(map);
    }
    else{
      if(global.is_cordova()){
        var base=L.tileLayer('http://{s}.tile.osm.org/{z}/{x}/{y}.png', {
          // attribution: '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
          maxZoom: 20,
          maxNativeZoom: 18
        });

        var Esri_WorldImagery = L.tileLayer('https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}', {
          // attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
          maxZoom: 20,
          maxNativeZoom: 18
        }).addTo(map);

        var baseMaps = {
          "Base": base,
          "Satellite": Esri_WorldImagery,
        };
      }
      else{
        var googleStreets = L.tileLayer('//{s}.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3']
        });

        var googleSat = L.tileLayer('//{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3']
        });

        var googleHybrid = L.tileLayer('//{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}',{
          maxZoom: 20,
          subdomains:['mt0','mt1','mt2','mt3']
        }).addTo(map);

        var baseMaps = {
          "Base": googleStreets,
          "Ibrida": googleHybrid,
          "Satellite": googleSat,
        };
      }

      L.control.layers(baseMaps,{}).addTo(map);

    }
    map.setView(defaultCenter, defaultZoom);

    if(markerLocation){
      global.marker = L.marker(defaultCenter).addTo(map);
    }
    global.map=map;
  }

  getHtml(changeCoord,runGPS, height, id_div){
      var body="";
      if(!height){
        height=200;
      }
      if (typeof runGPS=='undefined' || runGPS==true) {
        body+='<div class="block block-strong"><button style="display: flex;justify-content: center;align-items: center;" id="runGPS" class="col button button-large button-fill button-round"><i style="width: 24px;margin-right: 15px;color: white;" class="f7-icons">location_fill</i>Prendi coordinate GPS</button></div>';
      }

      if(changeCoord){
        body+="<input type='hidden' id='manual_gps_latitude' />";
        body+="<input type='hidden' id='manual_gps_longitude' />";
        body+="<input type='hidden' id='survey_gps_latitude' />";
        body+="<input type='hidden' id='survey_gps_longitude' />";
        body+="<input type='hidden' id='survey_gps_provider' />";

        body+='<div class="list simple-list" style="margin-bottom: -15px;">';
          body+='<ul>';
            body+='<li>';
              body+='<span>Sposta il punto</span>';
              body+='<label id="manual-Map-checkbox" class="toggle toggle-init color-green">';
                body+='<input id="manual-Map" type="checkbox">';
                body+='<span class="toggle-icon"></span>';
              body+='</label>';
            body+='</li>';
          body+='</ul>';
        body+='</div>';
      }
      body+=`<div id='${id_div?id_div:'map_container'}' style='height:${height}px; position: sticky;'></div>`;

      return body;
  }


  addMarker(coord){
    if(global.marker){
      global.map.removeLayer(global.marker);
    }
    global.marker=L.marker(coord).addTo(global.map);
  }


  fixMapHeight(id_div){
    try{
      var tm=jQuery("#"+(id_div?id_div:'map_container')).offset().top;
      var tf=jQuery("#footer").offset().top;
  
      jQuery("#"+(id_div?id_div:'map_container')).css("height",(tf-tm)+"px");
    }
    catch(e){
      ;
      console.log(e);
    }
  
  }

}





export default (mapHelper);
