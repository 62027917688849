/*jshint esversion: 6 */
import './style.css';

import global from '../../global.js';
import storage from '../../storage.js';
import notification from '../../core/notification.js';

import dbmng from '../../ext/dbmng.js';
import * as partecipa from '../../core/partecipa/partecipa_new.js';

// Se serve GPS
//*********************************************************************
import gps from '../../core/gps.js';
//*********************************************************************

// Se serve LEAFLET
//*********************************************************************
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});
//*********************************************************************
global.base_path='https://tosquilat.aedit.it/api/';
global.base_link='https://tosquilat.aedit.it/';
global.base_call=global.base_path+'userplus/';
global.nomeApp="Tosquilat";
global.firebaseConfig = {
  apiKey: "AIzaSyC8l78LBYmEnydX6KsmAXe_tviA3Rc22hc",
  authDomain: "tosquilat.firebaseapp.com",
  projectId: "tosquilat",
  storageBucket: "tosquilat.appspot.com",
  messagingSenderId: "279645967779",
  appId: "1:279645967779:web:9f0e19724662d5e2f13616",
  measurementId: "G-0X2GX965SL"
};

global.firebase=firebase.initializeApp(global.firebaseConfig);
global.app_version='1.0.04';


global.messaging = null;
global.settingsName='tosquilat';

global.skipFinalizza=true;
global.uploadFile=true;

if (firebase.messaging.isSupported()){
  global.messaging = firebase.messaging();
}

if (notification) {
  global.notification=notification;
}
notification.default_notify();
//Hook obbligatorio per definire la home progetto
export function home_page(date){
  console.log("date---- ", date);

  var html='';

  var s=storage.get();


  var request={}

  if (s.user && s.user.access_token) {
    request.access_token=s.user.access_token;
  }
  // };

  if (date) {
    request.date=date;
  }

  if(s.user) {
    var urladdr = global.base_path+"base/get_data";
    jQuery.ajax({
    type: 'POST',
    data:request,
    url: urladdr,
    dataType: "json",
      success: function(data_success){
        if(data_success.ok){
          if (data_success.data.length>0) {
            html+=`Homepage! Ci sono ${data_success.data.length} record`;
          }
          else {
            html+=`Purtroppo ${data_success.data.length} record sono disponibili :(`;
          }



          // <select style="margin: auto;  font-weight: bold;" id="date_selector"></select>
          html=`
          <div style="text-align:center;" class="block-title">Ultima analisi disponibile del</div>
          <div class="block-title block-title-medium" style="text-align:center; margin-top: 0;">
          </div>

          <div class="list inset" style="width:50%;margin: auto;">
            <ul>
              <li>
                  <div id="date_div"></div>
              </li>
            </ul>
          </div>

          <div class="data-table card">
            <table id="tos_table">
              <thead>
              </thead>
              <tbody>
              </tbody>
            </table>
          </div>
          `;

          var content={'title': '', 'content': html, "exclude_card":true};
          global.theme.render(content);

          // var date_opt='';
          // jQuery.each(data_success.dates.data,function(k,v){
          //   var selected='';
          //   if (date && v.data_campione==date) {
          //     selected='selected';
          //   }
          //   date_opt+=`<option value=${v.data_campione} ${selected}>${v.data_campione}</option>`;
          // });
          // jQuery('#date_selector').html(date_opt);

          // debugger
          var select=datesSelect(data_success.dates.data ,date)
          // console.log(select);
          jQuery('#date_div').html(select);

          jQuery('#date_selector').change(function(){
            home_page(jQuery(this).val());
          });

          var tr=`
            <tr><td>Data</td><th>${data_success.data[0].data_campione}</th></tr>
            <tr><td>Num.</td><th>${data_success.data[0].numero_campione}</th></tr>
          `;
          jQuery('#tos_table tbody').append(tr);

          if (data_success.data.length>0) {
            jQuery.each(data_success.data,function(k,v){
              var tr=`
                <tr><td>${v.nome_analisi?v.nome_analisi:v.nome_analisi_esteso}</td><th>${v.valore}</th></tr>
              `;

              jQuery('#tos_table tbody').append(tr);
            });
          }
        }
        else{
          jQuery('#error_div').html("<div class='alert alert-danger'>"+data_success.message+"</div>");
        }
      },
      error: function(a,b,c){
        jQuery('#error_div').html("<div class='alert alert-danger'>Status "+a.status+" "+c+"</div>");
      }
    });
  }
  else {
    console.log(location.hash);
    location.hash = '#login';
  }

}

function datesSelect(dates ,selected){
  var html="";
  var default_opt='';

  //Insert the season

  var os="";
  jQuery.each(dates, function(ks,s){
    var sel="";
    if(s.data_campione==selected){
      sel="selected";
      default_opt=s.data_campione;
    }

    if (default_opt=='' && ks==0) {
      default_opt=s.data_campione;
    }
    os+=`<option ${sel} value=${s.data_campione}>${s.data_campione}</option>`;
  });

  var id='date_selector';


  html=`
    <a class="item-link smart-select smart-select-init" data-open-in="popover" data-close-on-select="true">
      <select id="${id}">${os}</select>
      <div class="item-content">
        <div class="item-inner">
          <div class="item-title">${''}</div>
          <div class="item-after" style="margin:auto;">${default_opt}</div>
        </div>
      </div>
    </a>
  `;
  return html;
}

//Hook obbligatorio per definire le route del progetto
export function exe_routes(router){
  router.on({
    'test': function () {
      var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      jQuery('.left').html(html);
      console.log("Test!");
    },
    'documents':function(){
      jQuery('#download_app').hide();

      jQuery('#footer').parent().show();
      jQuery('#footer').html('');

      documents();
    },
  });

  router=partecipa.exe_routes(router);

  return router;
}

export function get_theme_param(theme_param){
  var sidebars=[
    {'label': 'Home page', 'icon_f7':'house', 'href':'#'},
    {'label': 'Documenti', 'icon_f7':'doc', 'href':'#documents'},
    {'label': 'Schede', 'icon_f7':'square_list', 'href':'#partecipa'},

  ];
  var s=storage.get();
  if (s.user) {
    theme_param.uid=s.user.uid;
    theme_param.username=s.user.username;
  }
  theme_param.sidebars=sidebars;
  theme_param.title='Tosquilat';
  theme_param.ptr=false; // attiva/disattiva pull to refresh
  return theme_param;
}

export function documents(){
  var html='<div id="error_div"></div><div id="bp_div"></div>';
  var content={'title': '', 'content': html, exclude_card:true};
  global.theme.render(content);

  var s=storage.get();

  // var urladdr = 'https://pecorapp.aedit.it/api_mod_sheep_anag/'+"best_practice";
  // jQuery.ajax({
  //   type: "GET",
  //   url: urladdr,
  //   data:{
  //     access_token:s.user.access_token
  //   },
  //   dataType: "json",
  //   success: function(data_success){
      // if(data_success.ok){
        // console.log(data_success);


        var html=`
        <div class="block-title">Documenti</div>
        <div class="list media-list">
          <ul>
            <li>
              <a target="_blank" href="https://tosquilat.aedit.it/sites/default/files/manuale.pdf" class="item-link link external item-content">
                <div class="item-inner">
                  <div class="item-title-row">
                    <div class="item-title">Manuale dell'app</div>
                  </div>
                  <div class="item-subtitle">Aggiornato il 21/07/2022</div>
                  <div class="item-text">Apri il PDF</div>
                </div>
              </a>
            </li>
          </ul>
        </div>
        `;
        jQuery('#bp_div').html(html);
      // }
      // else{
      //   jQuery('#error_div').html(`<div class="block-title block-title-medium">Error: ${data_success.message}</div>`);
      // }
  //   },
  //   error: function(a,b,c){
  //     console.log(a);
  //     jQuery('#error_div').html(`<div class="block-title block-title-medium">Error status: ${a.status} ${c}</div>`);
  //   }
  // });
}
