/*jshint esversion: 6 */

import init from '../../init.js';
import global from '../../global.js';
import * as theme from '../../themes/f7/f7.js';
import * as project from './index.js';

export function init_app(){
  init(theme, project);
}

init_app();
