/*jshint esversion: 6 */

export function extendSelectNMWidgetF7(){

  var selectNMWidgetF7 = Dbmng.SelectNMWidget.extend({
    createWidget: function(){
      var self=this;

      self.aField.value = self.getFieldValue();

      var list='';
      if( typeof self.aField.voc_val[0] == 'object' ) {

        //cicla sugli elementi
        jQuery.each(self.aField.voc_val, function(k,v){
          jQuery.each(v, function(index, el) {

            var chkd="";
            jQuery.each(self.aField.value, function(_k, _v) {
              if(_v==index){
                chkd='checked="checked"';
              }
            });

            // list+="<div>"+JSON.stringify(self.aField.value)+"</div>";
            list+='<li>';
              list+='<label class="item-checkbox item-content">';
                list+='<input '+chkd+' class="real_widget" type="checkbox" name="demo-checkbox" id="'+index+'"/>';
                list+='<i class="icon icon-checkbox"></i>';
                list+='<div class="item-inner">';
                  list+='<div class="item-title">'+el+'</div>';
                list+='</div>';
              list+='</label>';
            list+='</li>';
          });
        });
      }

      var html='';
      html+='<div class="list">';
        html+='<ul>';
        html+=list;
        html+='</ul>';
      html+='</div>';
      return jQuery(html)[0];
    },
    getValue: function(){

      var active=[];
      var element=jQuery(this.widget).parent().parent().parent().find("input");

      jQuery.each(element,function(k,v){
        if (jQuery(v).is(":checked")==true) {
          active.push(jQuery(v).attr('id'));
        }
      });
      console.log("Diego",active);
      return (active);
    }
  });

  return selectNMWidgetF7;
}

export function extendAbstractWidgetQR(){  
  var abstractWidgetQR = Dbmng.AbstractWidget.extend({
    createWidget: function() {
      this.aField.value = this.getFieldValue();
      console.log("Klean QR", this.aField.qrcode);
      if(this.aField.qrcode){
        var el= document.createElement('div');
        el.className='item-input-wrap';


        var button=document.createElement('button');
        button.className='col button button-large button-raised';

        var icon=document.createElement('i');
        icon.className='icon f7-icons';
        icon.innerHTML='qrcode';
        button.appendChild(icon);

        var text=document.createElement('span');
        text.innerHTML='Scan QR Code';
        button.appendChild(text);

        el.appendChild(button);

        // give style margin-bottom:15px; to the button

        var input=document.createElement('input');
        input.className='real_widget';
        input.type='text';
        input.placeholder='or write here the QR Code value';
        el.appendChild(input);



        // button click event
        button.addEventListener('click', function(e) {
          e.preventDefault();
          e.stopPropagation();
          console.log("Klean QR", this.aField.qrcode);
          cordova.plugins.barcodeScanner.scan(
            function (result) {
              if(!result.cancelled){
                // if(result.format=="QR_CODE"){
                input.value=result.text;
                // }
              }
            },
            function (error) {
              alert("Scanning failed: " + error);
            }
          );
        }.bind(this));

        

        // assignAttributes(input, this.aField);

        // if(typeof this.aField.value !== 'undefined' ) {
        //   input.value=this.aField.value;
        // }
        // if( this.aField.placeholder ) {
        //   input.placeholder = "QR Code";
        // }
        // input.type = "text";
        // input.className= "real_widget";


        // //if in the option there is a field_type value it will be override the previous one (if the widget is hidden and the type is int it should be hidden)
        // if(this.aField.field_type){
        //   input.type = this.aField.field_type;
        // }

      }
      else{
        var el=this.theme.getInput(this.aField);
      }
      return el;
    },
  });
  
  return abstractWidgetQR;
}