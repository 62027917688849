/*jshint esversion: 6 */
import global from './global.js';


//Traduci la stringa t.
// t("casa") -> "Home"
//se esiste obj prova a tradurre la variabile aggiungendo un literal
// t(" mi chiamo ${nome} ", {nome: "Diego"}) -> "Mi chiamo Diego"
//per gestire la pluralisation occorre avere una stringa tradotta del tipo " nessun valore | 1 valore | {val} valore"
//t("{nome}_con_{auto}_auto", {auto:0, nome: "Diego"}) -> "Mi chiamo Diego e non ho auto"
//la stringa deve essere "{nome}_con_{auto}_auto": " Mi chiamo {nome} e non ho auto | Mi chiamo {nome} e ho 1 automobile  | Mi chiamo {nome} e ho {auto} automobili";
//Attenzione! per la pluralizzazione mettere nel json la variabile di cui considerare il numero al primo posro (va messa auto prioma di nome)
export default function t(input, obj){
  let self=this;

  let i18n=dictionary[global.language];

  let key='';
  try{
    key=input.toLowerCase().trim();
  }
  catch(e){
    key=input;
  }

  let ret=input;

  if(!i18n){
    ret=input;
  }
  else if (i18n=={}){
    ret=input;
  }
  else{
    if(i18n[key]){
      ret=i18n[key];
    }
    else{
      if(global.language!=='en'){
        console.log('||'+key+'|| not found');
      }
      ret=input;
    }
  }
  if(!ret){
    //print stack trace console

    // console.log("diego",ret,input);
  }
  if(ret && (ret+"").indexOf('|')>-1){
    console.log("die",ret);
    let iii=ret.split("|");
    try{
      for(let k in obj){
        let value_first_var=obj[k];
        console.log("die",value_first_var);

        if(value_first_var==0){
          ret=iii[0];
        }
        else if(value_first_var==1){
          ret=iii[1];
        }
        else{
          ret=iii[2];
        }
        break;
      }
    }
    catch(e){
      console.log("die",e);
    }
  }

  if(obj){
    for(let k in obj){
      ret=ret.replace('{'+k+'}',obj[k]);
    }
  }

  return ret;
}

let dictionary={
  "it": {
    "barley":"Orzo",
    "maize (corn)":"Mais",
    "grapes":"Vite",
    "olive":"Olivo",
    "ciao":"Ciao",
    "accedi":"Accedi",
    "show_password":"Mostra password",
    "welcome_tec": "Benvenuto tecnico",
    "farm": "Azienda",
    "action": "Azione",
    "actions": "Azioni",
    "welcome": "Benvenuto",
    "access_anagrafica":"Accedi all'anagrafica",
    "anagrafe":"Anagrafe",
    "su":"su",
    "mesi":"mesi",
    "parti":"parti",
    "search_in_table":"Cerca in tabella..",
    "advanced_registry":"Anagrafica avanzata",
    "chose_language": "Scegli la tua lingua",
    "synchronize_data": "Sincronizza i dati",
    "back_home_page": "Torna alla Home Page",
    "impostazioni": "Impostazioni",
    "versione":"Versione",
    "rams_choice":"Scelta degli arieti",
    "ram_message":"Da questa funzione è possibile vedere la compatibilità del proprio gregge con gli arieti degli altri utenti e contattarne i proprietari.",
    "go_to_choice":"Vai alla scelta",
    "back":"Indietro",

    // OLIVE pheno aeco
    'winter dormancy':'gemme invernali',
    'start bud break':'gemme gonfie',
    // 'bud break':'gemme aperte',
    'leaf development':'sviluppo fogliare',
    'shoot development':'accrescimento germoglio',
    'inflorescence buds are closed':'comparsa grappoli fiorali',
    'inflorescence buds start to swell':'rigonfiamento bottoni fiorali',
    'corolla differentiation':'differenziazione corolla',
    'beginning of flowering':'inizio fioritura',
    'full flowering':'fioritura 50%',
    'fruit set':'allegagione',
    'fruit growth after fruit set':'accresc. frutto post allegagione',
    'stone hardening 0-50%':'indurimento del nocciolo (0-50%)',
    'stone hardening > 50%':'indurimento del nocciolo (>50%)',
    'stone hardening 100%':'nocciolo indurito',
    'fruit growth green olives':'accrescimento frutto olive verdi',
    'fruit growth yellow-green olives':'accrescimento frutto olive giallo verdi',
    'veraison 5%':'Inizio Invaiatura 5%',
    'veraison 50%':'invaiatura 50%',
    'veraison 100%':'invaiatura 100%',

    // Grape pheno aeco
    'bud break':'germogliamento',
    'beginning leaf unfolded':'prime foglie distese',
    '5 leaves unfolded':'tralci lunghi 10 cm',
    'inflorescence visible':'grappoli visibili',
    'inflorescence swelling':'grappoli distesi',
    'flowers separating':'bottoni fiorali separati',
    'beginning of flowering (10%)':'inizio fioritura (10%)',
    'full flowering (50%)':'piena fioritura (50%)',
    'fruit set (10%)':'inizio allegagione 10%',
    'fruit set (50%)':'allegagione 50%',
    'fruit set (100%)':'allegagione completa',
    'berries groat-sized':'acino grano di pepe',
    'berries pea-sized':'acino pisello',
    'berries beginning to touch':'prechiusura grappolo',
    'berries touching':'grappolo chiuso',
    'beginning of ripening':'inizio invaiatura',
    'berries developing colour':'invaiatura 50%',
    'softening of berries':'invaiatura completa',
    'maturation':'maturazione',
    'agronomist':'Agronomo/Consulente',
    'num_no':'Olive sane',
    'num_si':'Olive punturate',

    //Modelli mais

    // Modello Helicoverpa IT
    "aeco_corn_heli_adults":"Adulti",
    "aeco_corn_heli_adult_preovi":"Adulti non ovideponenti",
    "aeco_corn_heli_adult_ovi":"Adulti ovideponenti",
    "aeco_corn_heli_egg":"Uova",
    "aeco_corn_heli_larvae":"Larve",
    "aeco_corn_heli_pupae":"Pupe",
    "aeco_corn_heli_adult_emergence2":"Sfarfallamento adulti, seconda generazione",
    // Modello Helicoverpa IT messages
    "aeco_corn_heli_adults_message":"Gli adulti stanno sfarfallando",
    "aeco_corn_heli_adult_preovi_message":"A breve inizierà l’ovideposizione",
    "aeco_corn_heli_adult_ovi_message":"L’ovideposizione è in corso, valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_heli_egg_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_heli_larvae_message":"Non è il momento di trattare",
    "aeco_corn_heli_pupae_message":"Non è il momento di trattare",
    "aeco_corn_heli_adult_emergence2_message":"A breve inizierà l’ovideposizione",

    // Modello Diabrotica IT
    "aeco_corn_diabro_overwintering":"Uova",
    "aeco_corn_diabro_young_larvae":"Larve di prima età",
    "aeco_corn_diabro_larvae_in_soil":"Larve nel suolo",
    "aeco_corn_diabro_soil_crysalid":"Crisalidi nel suolo",
    "aeco_corn_diabro_flight_start":"Inizio dei voli",
    "aeco_corn_diabro_flight_peak":"Picco dei voli",
    "aeco_corn_diabro_flight_decrease":"Diminuzione dei voli",
    "aeco_corn_diabro_flight_ended":"Fine dei voli",
    // Modello Diabrotica IT messages
    "aeco_corn_diabro_overwintering_message":"Non è il momento di trattare",
    "aeco_corn_diabro_young_larvae_message":"Non è il momento di trattare",
    "aeco_corn_diabro_larvae_in_soil_message":"Non è il momento di trattare",
    "aeco_corn_diabro_soil_crysalid_message":"Lo sfarfallamento degli adulti inizierà presto",
    "aeco_corn_diabro_flight_start_message":"Prepararsi al trattamento",
    "aeco_corn_diabro_flight_peak_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_diabro_flight_decrease_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_diabro_flight_ended_message":"Non è il momento di trattare",

    //Modello Ostrinia IT
    "aeco_corn_ostrinia_-":"Modello non ancora partito",
    "aeco_corn_ostrinia_overwintering":"Forme svernanti",
    "aeco_corn_ostrinia_adult_0":"Adulti",
    "aeco_corn_ostrinia_egg_1":"Uova",
    "aeco_corn_ostrinia_l1_1":"Larve di prima età, prima generazione",
    "aeco_corn_ostrinia_l2_1":"Larve di seconda età, prima generazione",
    "aeco_corn_ostrinia_l3_1":"Larve di terza età, prima generazione",
    "aeco_corn_ostrinia_l4_1":"Larve di quarta età, prima generazione",
    "aeco_corn_ostrinia_l5_1":"Larve di quinta età, prima generazione",
    "aeco_corn_ostrinia_cr_1":"Crisalidi, prima generazione",
    "aeco_corn_ostrinia_ad_1":"Adulti, prima generazione",
    "aeco_corn_ostrinia_egg_2":"Uova, seconda generazione",
    "aeco_corn_ostrinia_l1_2":"Larve di prima età, seconda generazione",
    "aeco_corn_ostrinia_l2_2":"Larve di seconda età, seconda generazione",
    "aeco_corn_ostrinia_l3_2":"Larve di terza età, seconda generazione",
    "aeco_corn_ostrinia_l4_2":"Larve di quarta età, seconda generazione",
    "aeco_corn_ostrinia_l5_2":"Larve di quinta età, seconda generazione",
    "aeco_corn_ostrinia_cr_2":"Crisalidi, seconda generazione",
    "aeco_corn_ostrinia_ad_2":"Adulti, seconda generazione",
    "aeco_corn_ostrinia_egg_3":"Uova, terza generazione",
    "aeco_corn_ostrinia_lx_3":"Larve, terza generazione",
    "aeco_corn_ostrinia_end_3":"Ciclo concluso",
    //Modello Ostrinia IT messages
    "aeco_corn_ostrinia_-_message":"",
    "aeco_corn_ostrinia_overwintering_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_adult_0_message":"A breve inizierà l’ovideposizione, prepararsi al trattamento",
    "aeco_corn_ostrinia_egg_1_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_ostrinia_l1_1_message":"È iniziato lo sviluppo larvale, valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_ostrinia_l2_1_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_l3_1_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_l4_1_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_l5_1_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_cr_1_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_ad_1_message":"A breve inizierà l’ovideposizione, prepararsi al trattamento",
    "aeco_corn_ostrinia_egg_2_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_ostrinia_l1_2_message":"È iniziato lo sviluppo larvale, valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_ostrinia_l2_2_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_l3_2_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_l4_2_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_l5_2_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_cr_2_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_ad_2_message":"A breve inizierà l’ovideposizione, prepararsi al trattamento",
    "aeco_corn_ostrinia_egg_3_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_corn_ostrinia_lx_3_message":"Non è il momento di trattare",
    "aeco_corn_ostrinia_end_3_message":"Non è il momento di trattare",

    // Modello Plasmo IT
    "aeco_grape_plasmo_20":"Non ci sono infezioni in atto",
    "aeco_grape_plasmo_110":"Ci sono infezioni in atto",
    "aeco_grape_plasmo_120":"Ci sono infezioni in atto",
    "aeco_grape_plasmo_130":"Ci sono infezioni in atto",
    "aeco_grape_plasmo_140":"Ci sono infezioni in atto",
    "aeco_grape_plasmo_0":"Il modello non è ancora partito",
    // Modello Plasmo IT message
    "aeco_grape_plasmo_20_message":"Non è il momento di trattare",
    "aeco_grape_plasmo_110_message":"Valuta di trattare con prodotti citotropici o sistemici",
    "aeco_grape_plasmo_120_message":"Valuta di trattare con prodotti sistemici",
    "aeco_grape_plasmo_130_message":"Attendi per trattare",
    "aeco_grape_plasmo_140_message":"Valuta di trattare con prodotti di contatto",
    "aeco_grape_plasmo_0_message":"Non è il momento di trattare",

    // Modello Oidio IT
    "aeco_grape_powdery_not_started":"Il modello non è ancora partito",
    "aeco_grape_powdery_risk_30":"Rischio basso",
    "aeco_grape_powdery_risk_50":"Rischio medio",
    "aeco_grape_powdery_risk_100":"Rischio alto",
    // Modello Oidio IT message
    "aeco_grape_powdery_not_started_message":"",
    "aeco_grape_powdery_risk_30_message":"Per trattamenti con zolfo si raccomanda una frequenza di 14-21 giorni",
    "aeco_grape_powdery_risk_50_message":"Per trattamenti con zolfo si raccomanda una frequenza di 10-17 giorni",
    "aeco_grape_powdery_risk_100_message":"Per trattamenti con zolfo si raccomanda una frequenza di 7 giorni",

    // Modello Lobesia IT
    "aeco_grape_lobesia_c0":"Crisalidi svernanti",
    "aeco_grape_lobesia_a1":"Adulti",
    "aeco_grape_lobesia_u1":"Uova, prima generazione",
    "aeco_grape_lobesia_l1":"Larve, prima generazione",
    "aeco_grape_lobesia_c1":"Crisalidi, prima generazione",
    "aeco_grape_lobesia_a2":"Adulti, prima generazione",
    "aeco_grape_lobesia_u2":"Uova, seconda generazione",
    "aeco_grape_lobesia_l2":"Larve, seconda generazione",
    "aeco_grape_lobesia_c2":"Crisalidi, seconda generazione",
    "aeco_grape_lobesia_a3":"Adulti, seconda generazione",
    "aeco_grape_lobesia_u3":"Uova, terza generazione",
    "aeco_grape_lobesia_l3":"Larve, terza generazione",
    "aeco_grape_lobesia_c3":"Crisalidi, terza generazione",
    "aeco_grape_lobesia_end_cycle":"Fine del ciclo",
    // Modello Lobesia IT message
    "aeco_grape_lobesia_c0_message":"Non è il momento di trattare",
    "aeco_grape_lobesia_a1_message":"L’ovideposizione inizierà presto, prepararsi al trattamento",
    "aeco_grape_lobesia_u1_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_grape_lobesia_l1_message":"Non è il momento di trattare",
    "aeco_grape_lobesia_c1_message":"Non è il momento di trattare",
    "aeco_grape_lobesia_a2_message":"L’ovideposizione inizierà presto, prepararsi al trattamento",
    "aeco_grape_lobesia_u2_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_grape_lobesia_l2_message":"Non è il momento di trattare",
    "aeco_grape_lobesia_c2_message":"Non è il momento di trattare",
    "aeco_grape_lobesia_a3_message":"L’ovideposizione inizierà presto, prepararsi al trattamento",
    "aeco_grape_lobesia_u3_message":"Valutare la possibilità di trattare, se non è già stato fatto",
    "aeco_grape_lobesia_l3_message":"Non è il momento di trattare",
    "aeco_grape_lobesia_c3_message":"Non è il momento di trattare",
    "aeco_grape_lobesia_end_cycle_message":"Non è il momento di trattare",


  },
  "en": {
    '':'',
    '':'',
    '':'',
    'grazie anche ai finanziamenti del progetto':'with the financial support of the H2020 FAIRshare project',
    'la web-app poderi è stata sviluppata da':'The web-app Poderi was developed by',
    'caricamento chat in corso':'LOADING THE CHAT',
    'crea una nuova chat':'Create a new chat',
    'modello non ancora calcolato':'Model not yet calculated',
    'risultato':'Result',
    'mm da irrigare':'mm to irrigate',
    'gestione e rilievi trappole':'Field monitoring management',
    'modelli':'Models',
    'larva':'Larvae',
    'pupa':'Pupae',
    'adulto':'Adults',
    'uovo':'Eggs',
    'il modello previsionale stima che la fase raggiunta è':'the model estimate that the current development phase is',
    'considerando l’inizio delle ovideposizioni al':'Considering the beginning of the oviposition on',
    'la mortalità cumulata settimanale è del':'while the cumulative weekly mortality is',
    'il modello prevede che la mortalità giornaliera delle forme giovanili di mosca dell’olivo è del':'The model estimates that the daily mortality of the juvenile stages of the olive fly is ',
    'modifica la data di inizio delle ovideposizioni':'Modify the oviposition start date',
    'generazioni teoriche':'Theoretical generations',
    'il modello prevede che le ovideposizioni sono partite il':'The model estimate that the oviposition started on',
    'il modello prevede che le ovideposizioni non sono ancora partite':'The model predicts that the oviposition has not yet started',
    'rischio basso':'Low risk',
    'rischio alto':'High risk',
    'rischio medio':'Medium risk',
    'mortalità settimanale':'Weekly mortality',
    'mortalità giornaliera':'Daily mortality',
    'mortalità delle forme giovanili':'Mortality rate of juvenile stages',
    'irrigazione manuale':'Manual irrigations',
    'irrigazione automatica':'Automatic irrigations',
    'pioggia giornaliera':'Daily rainfall',
    'soglia superiore':'Upper threshold',
    'soglia inferiore':'Lower threshold',
    'foro uscita':'Exit holes',
    'pupa morta':'Dead pupae',
    'pupa viva':'Alive pupae',
    'larva 3° età morta':'Dead third instar larvae',
    'larva 3° età viva':'Alive third instar larvae',
    'larva 2° età morta':'Dead second instar larvae',
    'larva 2° età viva':'Alive second instar larvae',
    'larva 1° età morta':'Dead first instar larvae',
    'larva 1° età viva':'Alive first instar larvae',
    'totale olive campionate':'Number of sampled olives',
    'anno di impianto':'Year of planting',
    'irrigazione automatica':'Automatic irrigation',
    'aggiorna l\'app':'Update app',
    'nessuna':'None',
    'numero di catture della terza trappola':'Number of catches of the third trap',
    'numero di catture della seconda trappola':'Number of catches of the second trap',
    'numero di catture della prima trappola':'Number of catches of the first trap',
    'modifica rilievo esistente':'Editing existing survey',
    'inserisci nuovo rilievo':'Insert new survey',
    'è già presente un rilievo':'There is already a survey in this date',
    'attenzione':'Be careful',
    'su grappoli':'Bunches',
    'su infiorescenze':'Inflorescences',
    'su foglie':'Leaves',
    'tipo di attacco':'Type of infection',
    'presenza infezione':'Infection presence',
    'botrite della vite':'Gray mold',
    'oidio della vite':'Powdery mildew',
    'presenza su grappoli':'Symptoms on bunches',
    'presenza su foglie':'Symptoms on leaves',
    'peronospora della vite':'Downy mildew',
    'stadio sviluppo':'Development stage',
    'percentuale infestazione':'Percentage of infestation',
    'infestazione - tignoletta della vite':'European grapevine moth infestation',
    'catture - tignoletta della vite':'European grapevine moth: traps (number of catches)',
    'catture trappola 3':'Catches 3 trap',
    'catture trappola 2':'Catches 2 trap',
    'catture trappola 1':'Catches 1 trap',
    'altra operazione':'Other operation',
    'infezioni secondarie':'secondary infections',
    'infezioni primarie e':'primary infections and',
    'ci sono state':'Completed infections:',
    'ci sono delle infezioni in corso':'There are ongoing infections',
    // 'ci sono delle infezioni in atto':'There are infections taking place',
    'non ci sono infezioni':'There are no infections',
    'adulti':'Adults',
    'crisalidi':'Chrysalises',
    'larve':'Larvae',
    'uova':'Eggs',
    'irrigazioni effettuate':'Irrigations done',
    'periodo':'Period',
    'pioggia':'Rain',
    'etc cumulata':'ETc total',
    'etc media':'ETc average',
    'ultimi 7 giorni':'Last 7 days',
    'girasole':'Sunflower',
    'pomodoro':'Tomato',
    'cece':'Chickpea',
    'orzo':'Barley',
    'frumento duro':'Durum wheat',
    'pisello':'Pea',
    'lenticchia':'Lentil',
    'soia':'Soybean',
    'dss mosca dell\'olivo':'Olive fly DSS',
    'concimazione':'Fertilization',
    'oidio della vite':' Powdery mildew (Erisyphe necator)',
    'plasmopara della vite':'Downy mildew (Plasmopara viticola)',
    'lobesia della vite':'European grapevine moth (Lobesia botrana)',
    'basso rischio':'Low risk',
    'allerta':'Alert',
    'alto rischio':'High risk',
    'ottimo lavoro! mica tutti arrivano a leggere questo messaggio!':'Good job! Not everyone gets to read this message!',
    'a questo punto che fai? non ci arrivi a 100 olive?!':'What are you doing at this point? Can\'t you get to 100 olives ?!',
    'forza dai! ancora qualche oliva':'Come on! A few more olives',
    'osserva almeno 50 olive':'Look at at least 50 olives',
    'osserva la tua prima oliva':'Look at your first olive',
    'olive osservate':'olives observed',
    'punture su':'stings on',
    'infestazione al':'Infestation at',
    'puntura si':'YES Punctured',
    'puntura no':'NO Punctured',
    'num_no':'Healthy olives',
    'num_si':'Punctured olives',
    'catture':'Catches',
    'non c\'è nessuna scheda di monitoraggio per questa coltura':'There is no monitoring for this crop',
    'c\'è stato un errore nel caricamento dei dati statici.':'There was an error loading static data.',
    'caricamento in corso..':'Loading..',
    'chiudi':'Close',
    'seleziona':'Select',
    'clicca su':'Click on',
    'aggiungi alla schermata home':'Add to Home Screen',
    'installa':'Install',
    'dispositivi con attrattivi alimentari e/o feromonici e/o avvelenati con deltametrina':'Devices with food and / or pheromone attractants and / or poisoned with deltamethrin',
    'altro biologico':'Other organic',
    'dispositivi ad azione attract and kill':'Attract and kill action devices',
    'caolino':'Kaolin',
    'rame':'Copper',
    'altro larvicida':'Other Larvicida',
    'cattura massale':'Mass capture',
    'piretrine':'Pyrethrins',
    'spinosad con specifica esca':'Spinosad with specific lure',
    'esca proteica + acetamiprid':'Protein bait + acetamiprid',
    'aratura':'Plowing',
    'ripuntatura':'Subsoiling',
    'erpicatura':'Harrowing',
    'rullatura':'Rolling',
    'frangizzollatura':'Frangizzollatura',
    'fresatura':'Milling',
    'sarchiatura':'Weeding',
    'trinciatura':'Shredding',
    'sfalcio':'Mowing',
    'altro':'Other',
    'urea ammonio solfato':'Urea Ammonium Sulphate',
    'binario 25-15':'binary 25-15',
    'nitrato di magnesio':'Magnesium nitrate',
    'nitrato di calcio':'Calcium nitrate',
    'solfato di potassio':'Potassium sulfate',
    'solfato ammonico':'Ammonium sulfate',
    'scorie di Thomas':'Thomas slag',
    'polyhalite':'polyhalite',
    'perfosfato triplo':'Triple superphosphate',
    'perfosfato semplice':'Simple superphosphate',
    'nitrato di potassio 13 0 46':'potassium nitrate 13 0 46',
    'nitrato di ammonio 33.5 0 0':'Ammonium nitrate 33.5 0 0',
    'nitrato di ammonio 26 0 0':'Ammonium nitrate 26 0 0',
    'nitrato ammonico calcareo':'Calcareous ammonium nitrate',
    'fosforite':'Phosphorite',
    'fosfato monopotassico':'Monopotassium phosphate',
    'fosfato monoammonico':'Monoammonium phosphate',
    'fosfato bipotassico':'Dihypotassium phosphate',
    'fosfato biammonico':'Diammonium phosphate',
    'cloruro di potassio':'Potassium chloride',
    'cloruro d\'ammonio':'Ammonium chloride',
    'acido fosforico':'Phosphoric acid',
    'scegli un concime':'Choose a fertilizer',
    'compost':'Compost',
    'pollina':'Chicken manure',
    'liquame suino':'Pig slurry',
    'liquame bovino':'Bovine slurry',
    'letame equino':'Horse manure',
    'letame ovino':'Sheep manure',
    'letame bovino':'Bovine manure',
    'kilogrammi (kg)':'Kilograms (kg)',
    'grammi (g)':'Grams (g)',
    'parassiti':'Parasites',
    'molluschi':'Clams',
    'insetti':'Insects',
    'funghi':'Fungus',
    'infestanti':'Weeds',
    'litri (l)':'Liters (l)',
    'interrate in parte':'Partially buried',
    'interrate totalmente':'Completely buried',
    'asportate':'Removed',
    'non potato':'Not pruned',
    'graminacee':'Gramineae',
    'veccia':'Vetch',
    'favino':'Faba bean minor',
    'trifoglio incarnato':'Crimson clover',
    'trifoglio alessandrino':'Berseem clover',
    'trifoglio pratense':'Red clover',
    'erba medica':'Alfalfa',
    'nessuno':'None',
    'aggiungi l\'uso':'Add the use',
    'aggiungi un uso':'Add a use',
    'aggiungi una azienda':'Add a farm',
    'modello non gestito! contattare lo sviluppatore':'Unmanaged model! Contact the developer',
    'fine':'End',
    'modello non disponibile. si prega di connettersi alla rete':'Model not available. Please connect to the network',
    'non ci sono modelli disponibili':'There are no models available',
    'ultima':'last',
    'irrigazioni inserite':'Irrigations inserted',
    'portata':'Flow',
    'campo non irriguo':'Non-irrigated field',
    'impianto':'Irrigation system',
    'aggiungi un suolo':'Add soil',
    'attenzione! il campo non è associato a nessun suolo.':'Attention! The field is not associated with any soil.',
    'attenzione! la stagione è finita, stai vedendo un dato storico':'Attention! The season is over, you are seeing historical data',
    'attenzione! l\'anno selezionato esiste già':'Attention! The selected year already exists',
    'carica documenti':'Upload documents',
    'qual è il tuo ruolo?':'What\'s your role?',
    'oppure':'or',
    'accedi con google':'Login with Google',
    'vuoi davvero eliminare l\'azienda?':'Do you really want to eliminate the farm?',
    'errore nella cancellazione del campo':'Error in deleting the field',
    'non ha trovato il record':'Record not found',
    'errore nel salvataggio di':'Error saving',
    'errore di sincronizzazione':'Synchronization error',
    'ci sono stati degli errori durante la sincronizzazione':'There were some errors during the synchronization',
    'i dati sono stati salvati':'The data has been saved',
    'adesso':'Now',
    'aggiornato':'Updated',
    'attenzione, errore di connessione. questa funzione richiede di avere una connessione internet attiva':'Warning, connection error. This feature requires you to have an active internet connection',
    'potassio':'Potassium',
    'fosforo':'Phosphorus',
    'azoto':'Nitrogen',
    'potassio consigliato':'Recommended potassium',
    'potassio distribuito':'Distributed potassium',
    'potassio mancante':'Missing potassium',
    'azoto mancante':'Missing nitrogen',
    'azoto distribuito':'Distributed nitrogen',
    'azoto consigliato':'Recommended nitrogen',
    'fosforo mancante':'Missing phosphorus',
    'fosforo distribuito':'Distributed phosphorus',
    'fosforo consigliato':'Recommended phosphorus',
    'concimazioni inserite':'Fertilizers inserted',
    'nessuna concimazione inserita':'No fertilization inserted',
    'mortalità settimanale':'Weekly mortality',
    'mortalità':'Mortality',
    'data':'Date',
    'mortalità della mosca':'Fly mortality',
    'numero di olive punturate':'Number of punctured olives',
    'numero di olive sane':'Number of healthy olives',
    'olive punturate':'Punctured olives',
    'olive sane':'Healthy olives',
    'numero di catture':'Catch number',
    '00-gemme invernali':'00-winter dormancy',
    '01-gemme gonfie - apertura gemme':'01-start bud break',
    '09-gemme aperte':'09-bud break',
    "11-sviluppo fogliare":'11-leaf development',
    "33-accrescimento germoglio":'33-shoot development',
    "53-comparsa grappoli fiorali":'53-inflorescence buds are closed',
    "55-rigonfiamento bottoni fiorali":'55-inflorescence buds start to swell',
    "57-differenziazione corolla":'57-corolla differentiation',
    "61-inizio fioritura":'61-beginning of flowering',
    "65-fioritura 50%":'65-full flowering',
    "69-allegagione":'69-fruit set',
    "71-accresc. frutto post allegagione":'71-fruit growth after fruit set',
    "75-indurimento del nocciolo (0-50%)":'75-stone hardening 0-50%',
    "76-indurimento del nocciolo (>50%)":'76-stone hardening >50%',
    "77-nocciolo indurito":'77-stone hardening 100%',
    "79-accrescimento frutto olive verdi":'79-fruit growth green olives',
    "80-accrescimento frutto olive giallo verdi":'80-fruit growth yellow-green olives',
    "81-inizio invaiatura 5%":'81-veraison 5%',
    "83-invaiatura 50%":'83-veraison 50%',
    "85-invaiatura 100%":'85-veraison 100%',
    "91-maturazione":'91-maturation',
    "99-post raccolta":'99-post harvest',

    'fase media':'Middle phase',
    'infestazione completa':'Complete infestation',
    'infestazione semplice':'Simple infestation',
    'catture':'Catch',
    'fenologia dell\'olivo':'Phenology of the olive tree',
    'data di interramento':'Date of landfill',
    'gestione dei residui':'Residue management',
    'nome prodotto':'Product name',
    'quantità (kg/ha)':'Amount (kg/ha)',
    'fertilizzante':'Fertilizer',
    'potassio (k₂o) (kg/ha)':'Potassium (K₂O) (kg / ha)',
    'fosforo (p₂o₅) (kg/ha)':'Phosphorus (P₂O₅) (kg/ha)',
    'azoto ureico (kg/ha)':'Ureic nitrogen (kg / ha)',
    'azoto ammoniacale (kg/ha)':'Ammonia Nitrogen (kg/ha)',
    'azoto nitrico (kg/ha)':'Nitric nitrogen (kg/ha)',
    'unità/ha':'Unit/ha',
    'scegli un prodotto ed inserisci una quantità':'Choose a product and enter a quantity',
    'peso per ettaro':'Weight per hectare',
    'peso totale':'Total weight',
    'unità':'Unit',
    'nome commerciale':'Commercial name',
    'tipo prodotto':'Product type',
    'inserisci l\'irrigazione in mm':'Enter the irrigation in mm',
    'quantità (mm)':'Amount (mm)',
    'profondita (in cm)':'Depth (in cm)',
    'tipo di operazione':'Operation type',
    'scegli l\'operazione colturale':'Choose the cultivation operation',
    'trattamenti mosca dell\'olivo':'Treatments for olive fruit fly',
    'tipo di trattamento':'Treatment type',
    'quantità per ettaro':'Amount per hectare',
    'quantità totale':'Total amount',
    'unità di misura':'Unit of measure',
    'semina/trapianto':'Sowing/Transplanting',
    'lavorazione del suolo':'Soil tilling',
    'concimazione minerale':'Mineral fertilization',
    'concimazione organica':'Organic fertilization',
    'trattamenti':'Treatments',
    'potatura':'Pruning',
    'sovescio':'Green manure',
    'raccolta':'Harvest',
    'crea una nuova operazione colturale':'Create a new crop operation',
    'crea un nuovo monitoraggio':'Create a new monitoring',
    'modifica monitoraggio':'Edit monitoring',
    'aggiungi una nuova operazione':'Add a new operation',
    'elenco delle operazioni':'List of operations',
    'operazioni colturali':'Crop operations',
    'non ci sono dati':'There is no data',
    'aggiungi un nuovo rilievo':'Add a new observation',
    'elenco dei monitoraggi':'List of monitorings',
    'sto scaricando i dati':'Downloading the data',
    'crea la nuova stagione':'Create new season',
    'nuovo anno di raccolta':'New year of harvest',
    'crea una nuova stagione':'Create a new season',
    'coltura nuova stagione':'New season crop',
    'copia gli usi colturali dall\'anno':'Copy crop uses from the year',
    'nessuna coltura':'No crops',
    'anno di raccolta':'Harvest year',
    'servono almeno tre punti':'At least three points are needed',
    'poligono disegnato correttamente':'Polygon drawn correctly',
    'superficie in metri quadri':'Area in square meters',
    'non è possibile cancellare il campo, ci sono dei dati associati.':'It is not possible to delete the field, there is associated data.',
    'non coltivato':'not cultivated',
    'hai appena creato l\'azienda. crea il tuo primo campo per poter continuare.':'You just created the farm. Create your first field to be able to continue.',
    'nuova stagione':'New season',
    'nuova azienda':'New farm',
    'finisci':'Finish',
    'disegna il campo o inserisci la superficie':'Draw the field or insert the area',
    'poligono non disegnato':'Polygon not drawn',
    'uso del campo':'Field use',
    'spostati dove vuoi disegnare il primo punto e clicca su "inizia". se non vuoi disegnare il poligono clicca su "annulla".':'Move to where you want to draw the first point and click on "Start". If you don\'t want to draw the polygon click on "Cancel".',
    'inizia':'Start',
    'annulla':'Cancel',
    'disegna il campo':'Draw the field',
    'nome del campo':'Field name',
    'disegna il poligono':'Draw the polygon',
    'superficie in':'Area in',
    'coltura':'Crop',
    'uso':'Use',
    'manuale/di soccorso':'Manual/emergency',
    'irrigazione a goccia':'Drip irrigation',
    'impianto sprinkler':'Sprinkler system',
    'subirrigazione':'Sub Irrigation',
    'Irrigazione di superficie':'Surface irrigation',
    'altre tipologie di irrigazione':'Other types of irrigation',
    'irrigazione - portata in l/s':'Irrigation - Flow rate in l / s',
    'irrigazione':'Irrigation',
    'cancella l\'uso':'Delete the field use',
    'bassa':'Low',
    'alta':'High',
    'media':'Medium',
    'classe di vigoria':'Vigor class',
    'resa attesa':'Expected yield',
    'varietà':'Cultivar',
    'a':'in',
    'coltivato nella stagione':'cultivated in the',
    'stai modificando il campo':'You are editing the field',
    'aggiungi campo':'Add field',
    'salva i dati':'Save',
    'dipendente':'Employee',
    'proprietario':'Owner',
    'agronomo/consulente':'Agronomist',
    'vuoi davvero eliminare l\'uso del campo?':'Do you really want to delete the use of the field?',
    'vuoi davvero eliminare il collegamento?':'do you really want to delete the link?',
    'tu':'You',
    'in sospeso':'pending',
    "non è stato possibile creare l'invito. accertati di avere una connessione internet attiva.":'The invitation could not be created. Make sure you have an active internet connection.',
    'copia link':'Copy link',
    'invita col link':'Invite with link',
    'clicca al seguente indirizzo':'click on the following address',
    'come':'as',
    'se vuoi accedere all\'azienda':'If you want to access the farm',
    'benvenuto su':'Welcome to',
    'utenti associati all\'azienda':'Users associated with the farm',
    'non ci sono altri utenti collegati':'There are no other users connected',
    'manda un invito':'Send an invitation',
    'invita altre persone':'Invite other people',
    'funzioni':'Operation',
    'utente':'User',
    'ruolo':'Role',
    'scegli il ruolo':'Choose the role',
    'Inserisci il nome dell\'azienda':'Enter the farm name',
    'stazione meteo':'Weather station',
    'gestione stazione meteo':'Weather station management',
    'aggiorna':'Update',
    'inserimento azienda':'Farm insertion',
    'cerca l\'indirizzo dell\'azienda':'Search farm address',
    'posizione del centro aziendale':'Farm center location',
    'gestisci stazione meteo':'Manage weather station',
    'nome dell\'azienda':'Farm name',
    'azienda':'Farm',
    'vai':'Go',
    'aggiungi azienda':'Add farm',
    'ecco le aziende collegate a te':'Here are the farms connected to you',
    'sono un agronomo':'I am an agronomist',
    'sono una azienda':'I am a farm',
    'presente':'Present',
    'assente':'Absent',
    'geometria':'Geometry',
    'superficie':'Area',
    'campo':'Field',
    'mais':'Corn',
    'vite':'Grape',
    'olivo':'Olive',
    'lavori in corso. in questa pagina sarà possibile accedere ad una sintesi dei dati colturali.':'Works in progress. On this page it will be possible to access a summary of the crop data.',
    'mappa dei campi':'Fields map',
    'codice del campione':'Sample code',
    'data di campionamento':'Sampling date',
    'sostanza organica':'Organic substance',
    'azoto totale':'Total nitrogen',
    'fosforo':'Phosphorus',
    'potassio scambiabile':'Exchangeable potassium',
    'usa di default su tutti i campi':'Use by default on all fields',
    'salva':'Save',
    'usa suolo standard':'Use standard soil',
    'campi a cui il suolo è associato':'Fields with which the soil is associated',
    'crea un nuovo suolo':'Create a new soil',
    "vuoi davvero eliminare l'analisi del suolo":'do you really want to delete soil analysis?',
    'aggiungi suolo':'Add soil',
    'apri':'Open',
    'nome':'Name',
    'sabbia':'Sand',
    'argilla':'Clay',
    'elimina':'Delete',
    'attenzione. la somma di sabbia e argilla non può superare 100':'Caution. The sum of sand and clay cannot exceed 100',
    'suolo':'Soil',
    'usi del campo':'Uses of the field',
    'si prega di definire la cultivar del campo':'Please define the cultivar of the field',
    'modifica':'Edit',
    'nessuna fenologia inserita':'No phenology inserted',
    'si prega di definire la':'Please define the',
    'tipologia di irrigazione':'type of irrigation',
    '(se presente)':'(if any)',
    'giorni':'days',
    'giorno':'day',
    'evapotraspirazione della coltura':'Evapotranspiration of the culture',
    'pioggia totale':'Total rain',
    'giorni di stress idrico':'Days of water stress',
    'irrigazioni automatiche':'Automatic irrigations',
    'irrigazioni applicate dall’agricoltore':'Irrigations applied by the farmer',
    'data di riferimento':'Reference date',
    'nessuna irrigazione inserita':'No irrigation inserted',
    'aggiungi':'Add',
    'consulta il grafico':'Consult the chart',
    'campi':'Fields',
    'mappa':'Map',
    'aggiungi un campo':'Add field',
    'dati del campo':'Field data',
    'elenco dei campi':'List of fields',
    'gestisci le aziende':'Manage farms',
    "accessi all'azienda":'Access to the farm',
    'analisi dei suoli':'Soils analysis',
    'analisi del suolo':'Soil analysis',
    "modifica l'azienda":'Modify the farm',
    'visualizza i campi':'Show your fields',
    'monitoraggio':'Monitoring',
    'operazioni colturali':'Crop operations',
    'ordinamento colturale':'Cropping system',
    'stagione':'season',
    'modello':'Model',
    'fase prevalente':'Prevalent phase',
    // 'gradi giorno': 'day degrees',
    'elimina il campo':'delete the field',
    'gradi giorno cumulati':'Day degrees cumulated',
    'fenologia dell\'olivo':'Olive phenology',
    "ciao":"Hi",
    "accedi":"Log in",
    "show_password":"Show password",
    "welcome_tec": "Welcome technician",
    "farm": "Farm",
    "action": "Action",
    "action": "Actions",
    "welcome": "Welcome",
    "access_anagrafica":"Access the registry",
    "anagrafe":"Registry",
    "su":"over",
    "mesi":"months",
    "parti":"deliveries",
    "search_in_table":"Search in table..",
    "advanced_registry":"Advanced registry",
    "chose_language": "Choose your language",
    "synchronize_data": "Synchronize data",
    "back_home_page": "Back to Home Page",
    "impostazioni": "Settings",
    "versione":"Version",
    "rams_choice":"Choice of rams",
    "ram_message":"From this function is possible to see the compatibility of your flock with the rams of other users and contact its owners.",
    "go_to_choice":"Go to the choice",
    "back":"Back",
    "esci":"Log out",

    "stagione": "Season",
    "elenco dei monitoraggi": "Monitoring list",
    "aggiungi un nuovo rilievo": "Add a new observation",
    "trappola": "Trap",
    "trappole": "Traps",
    "adulti catturati": "Caught adults",
    "elenco delle trappole": "Traps list",
    "aggiungi una trappola": "Add a trap",
    "cancella una trappola": "Delete a trap",
    "modifica una trappola": "Edit a trap",

    "data di positionamento": "Installation date",
    "tipo di trappola": "Trap type",
    "insetto": "Insect",
    "cromo trappole": "Cromo traps",
    "trappole a feromoni": "Pheromon trap",
    "modifica la trappola": "Edit the trap",


    //Modello Ostrinia EN titles
    "aeco_corn_ostrinia_-":"Model not started",
    "aeco_corn_ostrinia_overwintering":"Overwintering",
    "aeco_corn_ostrinia_adult_0":"Adults",
    "aeco_corn_ostrinia_egg_1":"Eggs, first generation",
    "aeco_corn_ostrinia_l1_1":"First instar larvae, first generation",
    "aeco_corn_ostrinia_l2_1":"Second instar larvae, first generation",
    "aeco_corn_ostrinia_l3_1":"Third instar larvae, first generation",
    "aeco_corn_ostrinia_l4_1":"Fourth instar larvae, first generation",
    "aeco_corn_ostrinia_l5_1":"Fifth instar larvae, first generation",
    "aeco_corn_ostrinia_cr_1":"Chrysalids, first generation",
    "aeco_corn_ostrinia_ad_1":"Adults, first generation",
    "aeco_corn_ostrinia_egg_2":"Eggs, second generation",
    "aeco_corn_ostrinia_l1_2":"First instar larvae, second generation",
    "aeco_corn_ostrinia_l2_2":"Second instar larvae, second generation",
    "aeco_corn_ostrinia_l3_2":"Third instar larvae, second generation",
    "aeco_corn_ostrinia_l4_2":"Fourth instar larvae, second generation",
    "aeco_corn_ostrinia_l5_2":"Fifth instar larvae, second generation",
    "aeco_corn_ostrinia_cr_2":"Chrysalids, second generation",
    "aeco_corn_ostrinia_ad_2":"Adults, second generation",
    "aeco_corn_ostrinia_egg_3":"Eggs, third generation",
    "aeco_corn_ostrinia_lx_3":"Larvae, third generation",
    "aeco_corn_ostrinia_end_3":"Cycle ended",
    //Modello Ostrinia EN messages
    "aeco_corn_ostrinia_-_message":"",
    "aeco_corn_ostrinia_overwintering_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_adult_0_message":"Oviposition will start soon, getting ready for spraying",
    "aeco_corn_ostrinia_egg_1_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_ostrinia_l1_1_message":"The development of the larvae has begun, evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_ostrinia_l2_1_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_l3_1_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_l4_1_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_l5_1_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_cr_1_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_ad_1_message":"Oviposition will start soon, getting ready for spraying",
    "aeco_corn_ostrinia_egg_2_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_ostrinia_l1_2_message":"The development of the larvae has begun, evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_ostrinia_l2_2_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_l3_2_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_l4_2_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_l5_2_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_cr_2_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_ad_2_message":"Oviposition will start soon, getting ready for spraying",
    "aeco_corn_ostrinia_egg_3_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_ostrinia_lx_3_message":"It is not the right time to spray",
    "aeco_corn_ostrinia_end_3_message":"It is not the right time to spray",


    // Modello Diabrotica EN
    "aeco_corn_diabro_overwintering":"Eggs",
    "aeco_corn_diabro_young_larvae":"Young larvae",
    "aeco_corn_diabro_larvae_in_soil":"Larvae in soil",
    "aeco_corn_diabro_soil_crysalid":"Chrysalids in soil",
    "aeco_corn_diabro_flight_start":"Start of flights",
    "aeco_corn_diabro_flight_peak":"Peak of flights",
    "aeco_corn_diabro_flight_decrease":"Decrease of flights",
    "aeco_corn_diabro_flight_ended":"End of flights",
    // Modello Diabrotica EN messages
    "aeco_corn_diabro_overwintering_message":"It is not the right time to spray",
    "aeco_corn_diabro_young_larvae_message":"It is not the right time to spray",
    "aeco_corn_diabro_larvae_in_soil_message":"It is not the right time to spray",
    "aeco_corn_diabro_soil_crysalid_message":"The adult flights will start soon",
    "aeco_corn_diabro_flight_start_message":"Getting ready for spraying",
    "aeco_corn_diabro_flight_peak_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_diabro_flight_decrease_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_diabro_flight_ended_message":"It is not the right time to spray",


    // Modello Helicoverpa EN
    "aeco_corn_heli_adults":"Adults",
    "aeco_corn_heli_adult_preovi":"Non oviponents adults",
    "aeco_corn_heli_adult_ovi":"Oviponents adults",
    "aeco_corn_heli_egg":"Eggs",
    "aeco_corn_heli_larvae":"Larvae",
    "aeco_corn_heli_pupae":"Pupae",
    "aeco_corn_heli_adult_emergence2":"Adult emergence, second generation",
    // Modello Helicoverpa EN messages
    "aeco_corn_heli_adults_message":"Adults are emerging",
    "aeco_corn_heli_adult_preovi_message":"Oviposition will start soon",
    "aeco_corn_heli_adult_ovi_message":"Oviposition is ongoing, evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_heli_egg_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_corn_heli_larvae_message":"It is not the right time to spray",
    "aeco_corn_heli_pupae_message":"It is not the right time to spray",
    "aeco_corn_heli_adult_emergence2_message":"Oviposition will start soon",


    // Modello Plasmo EN
    "aeco_grape_plasmo_20":"No infections are ongoing",
    "aeco_grape_plasmo_110":"Infections are ongoing",
    "aeco_grape_plasmo_120":"Infections are ongoing",
    "aeco_grape_plasmo_130":"Infections are ongoing",
    "aeco_grape_plasmo_140":"Infections are ongoing",
    "aeco_grape_plasmo_0":"Model is not started",
    // Modello Plasmo EN message
    "aeco_grape_plasmo_20_message":"It is not the right time to spray",
    "aeco_grape_plasmo_110_message":"Evaluate the possibility of applying cytotropic or systemic products",
    "aeco_grape_plasmo_120_message":"Evaluate the possibility of applying systemic products",
    "aeco_grape_plasmo_130_message":"Wait to spray",
    "aeco_grape_plasmo_140_message":"Evaluate the possibility of applying contact products",
    "aeco_grape_plasmo_0_message":"It is not the right time to spray",

    // Modello Oidio EN
    "aeco_grape_powdery_not_started":"Model not started",
    "aeco_grape_powdery_risk_30":"Low risk",
    "aeco_grape_powdery_risk_50":"Moderate risk",
    "aeco_grape_powdery_risk_100":"High risk",
    // Modello Oidio EN message
    "aeco_grape_powdery_not_started_message":"",
    "aeco_grape_powdery_risk_30_message":"For sulfur applications the recommended frequency is 14-21 days",
    "aeco_grape_powdery_risk_50_message":"For sulfur applications the recommended frequency is 10-17 days",
    "aeco_grape_powdery_risk_100_message":"For sulfur applications the recommended frequency is 7 days",

    // Modello Lobesia EN
    "aeco_grape_lobesia_c0":"Overwintering chrysalids",
    "aeco_grape_lobesia_a1":"Adults",
    "aeco_grape_lobesia_u1":"Eggs, first generation",
    "aeco_grape_lobesia_l1":"Larvae, first generation",
    "aeco_grape_lobesia_c1":"Chrysalids, first generation",
    "aeco_grape_lobesia_a2":"Adults, first generation",
    "aeco_grape_lobesia_u2":"Eggs, second generation",
    "aeco_grape_lobesia_l2":"Larvae, second generation",
    "aeco_grape_lobesia_c2":"Chrysalids, second generation",
    "aeco_grape_lobesia_a3":"Adults, second generation",
    "aeco_grape_lobesia_u3":"Eggs, third generation",
    "aeco_grape_lobesia_l3":"Larvae, third generation",
    "aeco_grape_lobesia_c3":"Chrysalids, third generation",
    "aeco_grape_lobesia_end_cycle":"End of cycle",
    // Modello Lobesia EN message
    "aeco_grape_lobesia_c0_message":"It is not the right time to spray",
    "aeco_grape_lobesia_a1_message":"Oviposition will start soon, getting ready for spraying",
    "aeco_grape_lobesia_u1_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_grape_lobesia_l1_message":"It is not the right time to spray",
    "aeco_grape_lobesia_c1_message":"It is not the right time to spray",
    "aeco_grape_lobesia_a2_message":"Oviposition will start soon, getting ready for spraying",
    "aeco_grape_lobesia_u2_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_grape_lobesia_l2_message":"It is not the right time to spray",
    "aeco_grape_lobesia_c2_message":"It is not the right time to spray",
    "aeco_grape_lobesia_a3_message":"Oviposition will start soon, getting ready for spraying",
    "aeco_grape_lobesia_u3_message":"Evaluate the possibility of spraying, if it has not already been done",
    "aeco_grape_lobesia_l3_message":"It is not the right time to spray",
    "aeco_grape_lobesia_c3_message":"It is not the right time to spray",
    "aeco_grape_lobesia_end_cycle_message":"It is not the right time to spray",

  }
};
