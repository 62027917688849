import global from "../global.js";
import storage from "../storage.js";
import * as tools from "../tools.js";
import t from '../translate.js';


var OfflineArray = {};
var SIZE = 100 * 1024 * 1024; // 100 MB

var errorFct = function (e) {
  console.error(e);
};

var getFileSystem = function (successFct) {
  if (navigator.webkitPersistentStorage) {    
    navigator.webkitPersistentStorage.requestQuota(SIZE, function () {
      window.webkitRequestFileSystem(window.PERSISTENT, SIZE, successFct, errorFct);
    }, errorFct);
  }
};


  var createTempName = function () {
    return 'temp.name.dummy.jpg';
  };

  var addToSyncQueue = function (filename) {
    // adding to sync queue
    console.log('Adding to queue', filename);
  };

  var showImage = function (fileName,imgno) {
    var src = 'filesystem:' + window.location.origin + '/persistent/' + fileName;
    return src;

  };

  var readImage = function (fileName, successFct) {
    getFileSystem(function (fileSystem) {
        fileSystem.root.getFile(fileName, {}, function (fileEntry) {

          fileEntry.file(successFct, errorFct);

        }, errorFct);
      }
    );
  };

  var writeSuccessFull = function (fileName,imgno) {
    //addToSyncQueue(fileName);

    var imgfile = showImage(fileName,imgno);
    console.log(imgfile);
    var cnt = localStorage.getItem('cnt');
    localStorage.setItem("offlineimg"+ cnt +"_"+ imgno, imgfile);
  };

  export default function writeImage(fileName, file,imgno) {
    getFileSystem(function (fileSystem) {
      fileSystem.root.getFile(fileName, {create: true}, function (fileEntry) {

        fileEntry.createWriter(function (fileWriter) {
          fileWriter.onwriteend = writeSuccessFull(fileName,imgno);

          fileWriter.onerror = errorFct;

          fileWriter.write(file);

        }, errorFct);

      });
    });
  }
