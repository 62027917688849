/*jshint esversion: 6 */
import global from "../../global.js";
import gps from '../../core/gps.js';
import t from '../../translate.js';

import * as tools from '../../tools.js';

import storage from '../../storage.js';
import dataManagement from "./data.js";
import * as partecipa_home from './partecipa_new_home.js';
import * as partecipa_storage from './partecipa_storage.js';
import * as partecipa_draw from './partecipa_new_draw.js';
import * as partecipatools from './partecipa_tools.js';
import * as partecipa_finalizza from './partecipa_finalizza.js';
import partecipaview from './partecipa_view.js';
import './partecipa.css';

var partecipa_view=new partecipaview({style:"f7"});

//--------------------------------------------//
//-----------COMMON DI PARTECIPA--------------//
//--------------------------------------------//

export function exe_routes(router){

  if (global.nomeApp=='Agro Abruzzo') {
    global.skipFinalizza=true;
  }



  router.on({
    // Aggiunge le routes di partecipa
    'partecipa': function () {
      home_page();
    },
    'view_data': function () {
      // alert('Ciao')
      partecipa_view.visualizzazioneDati();
    },
    'show_all_fields': function () {
      partecipatools.show_all_fields();
    },
    'field_detail/:guid': function (param) {
      partecipatools.show_field(param.guid);
    },
    ':id_farm/field_detail/new_field': function (param) {
      partecipatools.show_field(null, param.id_farm);
    },
    // Visualizzo i Survey per il SurveySchema
    ':id_farm/partecipa/:id_survey_schema': function (param) {
      var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      jQuery('.left').html(html);
      viewAllSurveys(param.id_farm, param.id_survey_schema);
    },
    // Creo u nuovo Survey
    ':id_farm/partecipa/:id_survey_schema/new': function (param) {
      var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      jQuery('.left').html(html);
      createNewSurveyOrOpenLast(param.id_farm, param.id_survey_schema);
    },
    // Va ad un survey esistente
    ':id_farm/partecipa/:id_survey_schema/:guid': function (param) {
      var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      jQuery('.left').html(html);

      openSurvey(param.id_farm, param.id_survey_schema, param.guid);
    },

    // Va ad un survey esistente
    ':id_farm/partecipa/:id_survey_schema/delete/:guid': function (param) {
      var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      jQuery('.left').html(html);

      partecipa_storage.deleteSurvey(param.id_farm, param.id_survey_schema, param.guid);
    },

    ':id_farm/finalizza/:id_survey_schema/:guid': function (param) {
      var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      jQuery('.left').html(html);

      partecipa_finalizza.finalizza(param.id_farm, param.id_survey_schema, param.guid);
    }

    //:id_farm/partecipa/:id_survey_schema/   crea un nuovo survey
    //:id_farm/partecipa/:id_survey_schema/:guid_survey/   mostra un survey
    //:id_farm/partecipa/:id_survey_schema/:guid_survey/2/   mostra un survey su quello step



    // ':id_farm/partecipa/storic': function (param) {
      //   var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
      //   jQuery('.left').html(html);
      //
      //   home_partecipa(param.id_farm,'storic');
      // },
    // ':id_farm/partecipa/storic/:id_survey_schema': function (param) {
    //   var html='<a href="javascript:history.back()" class="link" data-panel="left"><i class="f7-icons">chevron_left</i><span class="if-not-md">Back</span></a>';
    //   jQuery('.left').html(html);
    //
    //   openStoricSchema(param.id_farm,param.id_survey_schema);
    // },

  });

  return router;
}

//Chiamiamo la funzione nel file partecipa_home
export function home_page(){
  partecipa_home.home_page();
}

export function partecipa_tools(){
  partecipatools.partecipa_tools();
}


function openSurvey(id_farm, id_survey_schema, guid){
  //usiamo null per fargli usare lo step a cui lui è arrivato
  var step=null;
  var survey=partecipa_storage.selectSurvey(id_farm, id_survey_schema, guid);
  if (survey.step!=null) {
    step=survey.step;
  }

  viewSurvey(id_farm, id_survey_schema, guid,step);
}

// INSERIMENTO DEL SURVEY o apertura se ne esiste uno aperto
function createNewSurveyOrOpenLast(id_farm, id_survey_schema){
  var new_survey=createSurveyJSON(id_farm, id_survey_schema);


  var surveys=partecipa_storage.selectSurveysStorage(id_farm, id_survey_schema);
  var the_last_survey=surveys[surveys.length-1];

  // Quando vado a creare un nuovo survey controllo che non ce ne sia uno già aperto
  var the_survey=the_last_survey;
  if (!the_last_survey || the_last_survey.closed) {
    the_survey=new_survey;
    partecipa_storage.insertSurveyStorage(id_farm,id_survey_schema,the_survey);
  }

  //Questa funzione va a mdificare le coordinate di un sono un survey nuovo o un survey aperto e non chiuso
  //la visualizzazione dei dati non permette la modifica (a meno che lo faccio in finalizza)
  console.log("GPS2_start");
  gps.startGPS({
    buffer_max_size: 4,
    position_temp: function(pos){
      console.log("GPS2_temp",pos);
      updatePosSurvey(id_farm, id_survey_schema, the_survey.guid, pos);
    },
    position: function(pos){
      console.log("GPS2_final",pos);
      updatePosSurvey(id_farm, id_survey_schema, the_survey.guid, pos);
    },
    error: function(error){
      console.log("GPS not available");
    }
  });

  viewSurvey(id_farm, id_survey_schema, the_survey.guid, the_survey.step);
}



//update the location of the survey
function updatePosSurvey(id_farm, id_survey_schema, guid, pos){

  //TODO forse l'icona qui non è accessibile
  jQuery('#localizationIcon').html('<i class="f7-icons">location_fill</i>');
  if (global.is_cordova() && typeof pos=='string') {
    pos=JSON.parse(pos);
  }
  pos.timestamp=new Date().getTime();
  var toUpdate={
    coordinates:pos
  };
  partecipa_storage.updatePartialSurvey(id_farm, id_survey_schema, guid, toUpdate);

}

function viewSurvey(id_farm, id_survey_schema, guid, step){

  var survey_schema=partecipa_storage.selectSurveySchema(id_survey_schema);

  var survey=partecipa_storage.selectSurvey(id_farm, id_survey_schema, guid);


  if(survey_schema===null){

    global.theme.render({
      title: "Errore ",
      content: "Il survey schema richiesto non esiste"
    });

  }
  else if(survey===null){

    var page={
      title: "Errore ",
      content: "Il survey richiesto non esiste"
    };
    global.theme.render(page);
  }
  else{
    var schema={};
    if(typeof survey_schema.schema=='string'){
      schema=JSON.parse(survey_schema.schema);
    }
    if(global.plus){
      schema=survey_schema.schema;
    }

    var total_steps=schema.sections.length;

    surveySectionRender(schema, survey, id_farm, id_survey_schema);


    surveyButtons(id_farm, id_survey_schema,survey,total_steps);
  }
}

//OK fa il render del survey
function surveySectionRender(schema, survey , id_farm, id_survey_schema){

  var survey_step=survey.step;
  var section=schema.sections[survey_step];

  // TODO prende la section giusta
  if(!survey_step){
    survey_step=0;
  }

  if (typeof schema.sections=='undefined') {
    var body=t("Non ci sono sections presenti per questo survey_schema")+'.';
    var page={
      content: body,
    };
    global.theme.render(page);
  }
  else{
    if(schema.sections.length>=survey_step){
      if(section!=null){
        partecipa_draw.drawSpecificSection(section, survey, schema.scores, id_farm, id_survey_schema);
      }
    }
    else{

      global.theme.render({
        content: "Wrong survey step ("+survey_step+"/"+schema.sections.length+")"
      });
    }
  }
}


function surveyButtons(id_farm, id_survey_schema,survey,totalSteps){

  var step=survey.step;
  var guid=survey.guid;

  var buttons='';
  buttons+='<div class="col-xs-6">';

  if (step>0) {
    buttons+='<button id="indietro" class="button button-raised button-round button-fill partecipa-button">'+t("Indietro")+'</button>';
  }

  buttons+='</div>';
  buttons+='<div class="col-xs-6">';

  if (step<totalSteps-1) {
    buttons+='<button id="avanti" class="button button-raised button-round button-fill partecipa-button">'+t("Avanti")+'</button>';
  }
  else {
    buttons+='<button id="finalizza" class="button button-raised button-round button-fill partecipa-button">'+t("Finalizza")+'</button>';
  }

  buttons+='</div>';

  jQuery('#footer').html(buttons);

  jQuery("#avanti,#indietro").click(function() {
    var new_step=null;
    if (jQuery(this).attr('id')=='avanti') {
      new_step=step+=1;
    }
    else {
      new_step=step-=1;
    }

      
      partecipa_storage.saveDbmng(id_farm, id_survey_schema, guid, global.form);
      partecipa_storage.saveDbmng_inline(id_farm, id_survey_schema, guid, global.form_inline);

    //TODOServe? Controllare che le coordinate ci siano lo farei in finalizzazione
    //partecipa_storage.updatePartialSurvey(id_farm, id_survey_schema, survey.guid, {coordinates=global.coordinates});

    partecipa_storage.updatePartialSurvey(id_farm, id_survey_schema, guid, {step:new_step});
    openSurvey(id_farm, id_survey_schema, guid);
  });

  jQuery("#finalizza").click(function() {
      partecipa_storage.saveDbmng(id_farm, id_survey_schema, guid, global.form);
      partecipa_storage.saveDbmng_inline(id_farm, id_survey_schema, guid, global.form_inline);

    //TODO questo codice è probabilmente sbagliato, meglio che sia fatto all'inizio della pagina di finalizzazione
    // L'ho tolto (diego) tolto nel nuovo finalizza
    // var survey_to_change=partecipa_storage.selectSurvey(id_farm, id_survey_schema, guid);
    // if (!survey_to_change.coordinates) {
    //   var toUpdate={
    //     coordinates:global.coordinates
    //   };
    //   partecipa_storage.updatePartialSurvey(id_farm, id_survey_schema, guid, toUpdate);
    // }

    if (global.skipFinalizza) {
      // skipFinalizza per adesso è usato solo da tosquilat, non gli passiamo né i campi ([]), né l'id_crop
      partecipa_finalizza.finalizzaSaveSurvey(id_farm, survey, [], id_survey_schema);
    }
    else {
      location.hash="#"+id_farm+"/finalizza/"+id_survey_schema+"/"+guid;
    }

  });
}

// Funzione che mostra i survey già chiusi
function viewAllSurveys(id_farm, id_survey_schema){
  if (global.nomeApp=='Agro Abruzzo') {
    // Per evitare di far rimanere rosso il pallino dopo la modifica...
    var s=storage.get();
    var toSync=[];

    jQuery.each(s['survey_schema_'+id_farm],function(k,v){
      jQuery.each(v,function(i,d){
        if (d.sync!=true && d.closed ) {
          toSync.push({
            guid:d.guid,
            field_guid:d.field,
            id_survey_schema:k,
            val:JSON.stringify(d),
            date: d.timestamp.substr(0,10)
          });
        }
      });
    });

    if (toSync.length>0) {
      var dm=new dataManagement();
      dm.getSurveySchema(id_farm)
      .then( (arg) => {
        setTimeout(function() {
          location.reload();
        }, 1000);
      })
      .catch( (err) => {
        console.log(err);
        alert("Err:"+err);
      });
    }
  }
  
  var schemaHTML='';

  var survey_schema=partecipa_storage.selectSurveySchema(id_survey_schema);

  var surveys=partecipa_storage.selectSurveysStorage(id_farm, id_survey_schema);
  var fields=partecipa_storage.selectFieldsStorage(id_farm);

  schemaHTML+='<div id="showSurvey">';
    schemaHTML+='<div class="block block-strong"><button data-url="'+id_farm+'/partecipa/'+id_survey_schema+'/new" style="display: flex;justify-content: center;align-items: center;" class="col button button-large button-fill button-round"><i style="width: 24px;margin-right: 15px;color: white;" class="f7-icons">plus</i>Inizia '+survey_schema.name+'</button></div>';

    if (surveys.length>0) {
      schemaHTML+='<div class="block-title">Rilievi completati</div>';
      var body='';

      body+='<div class="data-table card">';
      body+='<table>';
        body+='<thead>';
          body+='<tr>';
            body+='<th></th>';
            body+='<th>'+t("Campo")+'</th>';
            body+='<th>'+t("Data")+'</th>';

            //Customizzazioni
            if (id_survey_schema==1) {
              body+='<th>'+t("Score")+'</th>';
            }
            else if (id_survey_schema==99){
              body+='<th>'+t("Punture totali")+'</th>';
              body+='<th>'+t("Punture si")+'</th>';
              body+='<th>'+t("Punture no")+'</th>';
            }
            else if (id_survey_schema==98){
              body+='<th>'+t("Fase")+'</th>';
              body+='<th>'+t("Carica produttiva")+'</th>';
            }
            else if (id_survey_schema==5){
              body+='<th>'+t("Media catture")+'</th>';
              body+='<th>'+t("Catture trappola 1")+'</th>';
              body+='<th>'+t("Catture trappola 2")+'</th>';
              body+='<th>'+t("Catture trappola 3")+'</th>';
            }


            body+='<th>'+t("Stato")+'</th>';
          body+='</tr>';
        body+='</thead>';
        body+='<tbody>';
          jQuery.each(surveys,function(k,v){


            body+="<tr data-url='"+id_farm+"/partecipa/"+id_survey_schema+"/"+v.guid+"'>";

            var color='red';
            if (v.sync) {
              color='green';
            }
            body+='<td><i class="icon f7-icons color-'+color+'">largecircle_fill_circle</i></td>';

            var nome_campo='---';
            jQuery.each(fields,function(j,d){
              if (d.guid==v.field) {
                nome_campo=d.name;
              }
            });
            body+='<td>'+nome_campo+'</td>';

            body+='<td>'+(v.timestamp.substring(0,10))+'</td>';

            try{
              if (id_survey_schema==1) {
                var score=0;
                jQuery.each(v.weights,function(k_weight,v_weigth){
                  score+=parseFloat(v_weigth);
                });

                if (score && v.closed) {
                  body+='<td>'+(score).toFixed(1)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
              }
              else if (id_survey_schema==99){
                body+='<td>'+calcolo_mosca(v.num_si,v.num_no)+'</td>';
                body+='<td>'+v.num_si+'</td>';
                body+='<td>'+v.num_no+'</td>';
              }
              else if (id_survey_schema==98){

                var fase=getFaseFenologica('fase_'+v.fase_fenologica);
                if(fase=="---" && v.fase_fenologica ){
                  fase="Classe "+v.fase_fenologica;
                }
                body+='<td>'+fase+'</td>';
                if (v.carica_produttiva) {
                  body+='<td>'+(v.carica_produttiva)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
              }
              else if (id_survey_schema==5){
                if (v.media_catture_trap) {
                  body+='<td>'+(v.media_catture_trap).toFixed(1)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
                if (v.catture_trap_1) {
                  body+='<td>'+(v.catture_trap_1)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
                if (v.catture_trap_2) {
                  body+='<td>'+(v.catture_trap_2)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
                if (v.catture_trap_3) {
                  body+='<td>'+(v.catture_trap_3)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
              }
            }
            catch(e){
              console.log(e);
            }

            if (v.closed) {
              body+='<td>'+t("Chiuso")+'</td>';
            }
            else {
              body+='<td>'+t("Aperto")+'</td>';
            }
            body+='<td class="deleteItem"><button data-url="'+id_farm+"/partecipa/"+id_survey_schema+"/delete/"+v.guid+'" id="deleteItem_'+v.guid+'" class="button"><i class="f7-icons">trash_circle_fill</i></button></td>';


            body+='</tr>';
          });
        body+='</tbody>';
      body+='</table>';
    body+='</div>';


      /*
      if (id_survey_schema==1) {
        body+='<div class="data-table card">';
          body+='<table>';
            body+='<thead>';
              body+='<tr>';
                body+='<th></th>';
                body+='<th>'+t("Campo")+'</th>';
                body+='<th>'+t("Data")+'</th>';
                body+='<th>'+t("Score")+'</th>';
                body+='<th>'+t("Stato")+'</th>';
              body+='</tr>';
            body+='</thead>';
            body+='<tbody>';
              jQuery.each(surveys,function(k,v){


                body+="<tr data-url='"+id_farm+"/partecipa/"+id_survey_schema+"/"+v.guid+"'>";

                var color='red';
                if (v.sync) {
                  color='green'
                }
                body+='<td><i class="icon f7-icons color-'+color+'">largecircle_fill_circle</i></td>';


                var nome_campo='---';
                if (v.coordinates!=null) {

                  jQuery.each(fields,function(j,d){
                    if (d.guid==v.field) {
                      nome_campo=d.name;
                    }
                  });

                  body+='<td>'+nome_campo+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }

                body+='<td>'+(v.timestamp.substring(0,10))+'</td>';

                var score=0;
                jQuery.each(v.weights,function(k_weight,v_weigth){
                  score+=parseFloat(v_weigth);
                });

                if (score && v.closed) {
                  body+='<td>'+(score).toFixed(1)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }

                if (v.closed) {
                  body+='<td>'+t("Chiuso")+'</td>';
                }
                else {
                  body+='<td>'+t("Aperto")+'</td>';
                }
                body+='<td class="deleteItem"><button data-url="'+id_farm+"/partecipa/"+id_survey_schema+"/delete/"+v.guid+'" id="deleteItem_'+v.guid+'" class="button"><i class="f7-icons">trash_circle_fill</i></button></td>';


                body+='</tr>';
              });
            body+='</tbody>';
          body+='</table>';
        body+='</div>';
      }
      else if (id_survey_schema==99) {
        body+='<div class="data-table card">';
          body+='<table>';
            body+='<thead>';
              body+='<tr>';
                body+='<th></th>';
                body+='<th>'+t("Campo")+'</th>';
                body+='<th>'+t("Data")+'</th>';
                body+='<th>'+t("Punture totali")+'</th>';
                body+='<th>'+t("Punture si")+'</th>';
                body+='<th>'+t("Punture no")+'</th>';
                body+='<th>'+t("Stato")+'</th>';
              body+='</tr>';
            body+='</thead>';
            body+='<tbody>';
              jQuery.each(surveys,function(k,v){



                body+="<tr data-url='"+id_farm+"/partecipa/"+id_survey_schema+"/"+v.guid+"'>";

                var color='red';
                if (v.sync) {
                  color='green'
                }
                body+='<td><i class="icon f7-icons color-'+color+'">largecircle_fill_circle</i></td>';

                var nome_campo='---';
                if (v.coordinates!=null) {
                  jQuery.each(fields,function(j,d){
                    if (d.guid==v.field) {
                      nome_campo=d.name;
                    }
                  });

                  body+='<td>'+nome_campo+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }

                body+='<td>'+(v.timestamp.substring(0,10))+'</td>';

                body+='<td>'+calcolo_mosca(v.num_si,v.num_no)+'</td>';

                body+='<td>'+v.num_si+'</td>';
                body+='<td>'+v.num_no+'</td>';
                if (v.closed) {
                  body+='<td>'+t("Chiuso")+'</td>';
                }
                else {
                  body+='<td>'+t("Aperto")+'</td>';
                }
                body+='<td class="deleteItem"><button data-url="'+id_farm+"/partecipa/"+id_survey_schema+"/delete/"+v.guid+'" id="deleteItem_'+v.guid+'" class="button"><i class="f7-icons">trash_circle_fill</i></button></td>';

                body+='</tr>';
              });
            body+='</tbody>';
          body+='</table>';
        body+='</div>';
      }
      else if (id_survey_schema==98) {
        body+='<div class="data-table card">';
          body+='<table>';
            body+='<thead>';
              body+='<tr class="swipeout">';
                body+='<th></th>';
                body+='<th>'+t("Campo")+'</th>';
                body+='<th>'+t("Data")+'</th>';
                body+='<th>'+t("Fase")+'</th>';
                body+='<th>'+t("Carica produttiva")+'</th>';
                body+='<th>'+t("Stato")+'</th>';
                body+='<th>'+t("Azione")+'</th>';
              body+='</tr>';
            body+='</thead>';
            body+='<tbody>';
              jQuery.each(surveys,function(k,v){
                body+="<tr data-url='"+id_farm+"/partecipa/"+id_survey_schema+"/"+v.guid+"'>";


                var color='red';
                if (v.sync) {
                  color='green'
                }
                body+='<td><i class="icon f7-icons color-'+color+'">largecircle_fill_circle</i></td>';


                var nome_campo='---';
                if (v.coordinates!=null) {
                  jQuery.each(fields,function(j,d){
                    if (d.guid==v.field) {
                      nome_campo=d.name;
                    }
                  });

                  body+='<td>'+nome_campo+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }

                body+='<td>'+(v.timestamp.substring(0,10))+'</td>';

                body+='<td>'+getFaseFenologica('fase_'+v.fase_fenologica)+'</td>';

                if (v.carica_produttiva) {
                  body+='<td>'+(v.carica_produttiva)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }

                if (v.closed) {
                  body+='<td>'+t("Chiuso")+'</td>';
                }
                else {
                  body+='<td>'+t("Aperto")+'</td>';
                }

                body+='<td id="deleteItem_'+v.guid+'" class="deleteItem"><button data-url="'+id_farm+"/partecipa/"+id_survey_schema+"/delete/"+v.guid+'" class="button"><i class="f7-icons">trash_circle_fill</i></button></td>';

                body+='</tr>';
              });
            body+='</tbody>';
          body+='</table>';
        body+='</div>';
      }
      else if (id_survey_schema==5) {
        body+='<div class="data-table card">';
          body+='<table>';
            body+='<thead>';
              body+='<tr>';
                body+='<th></th>';
                body+='<th>'+t("Campo")+'</th>';
                body+='<th>'+t("Data")+'</th>';
                body+='<th>'+t("Media catture")+'</th>';
                body+='<th>'+t("Catture trappola 1")+'</th>';
                body+='<th>'+t("Catture trappola 2")+'</th>';
                body+='<th>'+t("Catture trappola 3")+'</th>';
                body+='<th>'+t("Stato")+'</th>';
              body+='</tr>';
            body+='</thead>';
            body+='<tbody>';
              jQuery.each(surveys,function(k,v){
                body+="<tr data-url='"+id_farm+"/partecipa/"+id_survey_schema+"/"+v.guid+"'>";

                var color='red';
                if (v.sync) {
                  color='green'
                }
                body+='<td><i class="icon f7-icons color-'+color+'">largecircle_fill_circle</i></td>';


                var nome_campo='---';
                if (v.coordinates!=null) {
                  jQuery.each(fields,function(j,d){
                    if (d.guid==v.field) {
                      nome_campo=d.name;
                    }
                  });

                  body+='<td>'+nome_campo+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }

                body+='<td>'+(v.timestamp.substring(0,10))+'</td>';

                if (v.media_catture_trap) {
                  body+='<td>'+(v.media_catture_trap).toFixed(1)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }

                if (v.catture_trap_1) {
                  body+='<td>'+(v.catture_trap_1)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
                if (v.catture_trap_2) {
                  body+='<td>'+(v.catture_trap_2)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }
                if (v.catture_trap_3) {
                  body+='<td>'+(v.catture_trap_3)+'</td>';
                }
                else {
                  body+='<td>---</td>';
                }


                if (v.closed) {
                  body+='<td>'+t("Chiuso")+'</td>';
                }
                else {
                  body+='<td>'+t("Aperto")+'</td>';
                }
                body+='<td id="deleteItem_'+v.guid+'" class="deleteItem"><button data-url="'+id_farm+"/partecipa/"+id_survey_schema+"/delete/"+v.guid+'" class="button"><i class="f7-icons">trash_circle_fill</i></button></td>';


                body+='</tr>';
              });
            body+='</tbody>';
          body+='</table>';
        body+='</div>';
      }
      else {
        body+='<div class="data-table card">';
          body+='<table>';
            body+='<thead>';
              body+='<tr>';
                body+='<th></th>';
                body+='<th>'+t("Campo")+'</th>';
                body+='<th>'+t("Data")+'</th>';
                body+='<th>'+t("Stato")+'</th>';
              body+='</tr>';
            body+='</thead>';
            body+='<tbody>';

            jQuery.each(surveys,function(k,v){
              body+="<tr data-url='"+id_farm+"/partecipa/"+id_survey_schema+"/"+v.guid+"'>";

              var color='red';
              if (v.sync) {
                color='green'
              }
              body+='<td><i class="icon f7-icons color-'+color+'">largecircle_fill_circle</i></td>';


              var nome_campo='---';
              if (v.coordinates!=null) {
                jQuery.each(fields,function(j,d){
                  if (d.guid==v.field) {
                    nome_campo=d.name;
                  }
                });

                body+='<td>'+nome_campo+'</td>';
              }
              else {
                body+='<td>---</td>';
              }
              body+='<td>'+(v.timestamp.substring(0,10))+'</td>';
              if (v.closed) {
                body+='<td>'+t("Chiuso")+'</td>';
              }
              else {
                body+='<td>'+t("Aperto")+'</td>';
              }
              body+='<td class="deleteItem"><button data-url="'+id_farm+"/partecipa/"+id_survey_schema+"/delete/"+v.guid+'" id="deleteItem_'+v.guid+'" class="button"><i class="f7-icons">trash_circle_fill</i></button></td>';


              body+='</tr>';
            });



            body+='</tbody>';
          body+='</table>';
        body+='</div>';


      }
      */
      schemaHTML+=body;
    }

  schemaHTML+='</div>';

  var page={
    exclude_card:true,
    title: survey_schema.name,
    content: schemaHTML
  };
  global.theme.render(page);

  jQuery("#fromStoricToNew").click(function() {
     location.hash="#"+id_farm+"/partecipa/"+id_survey_schema;
  });

  jQuery("#showSurvey button, #showSurvey a, #showSurvey tr").click(function(){
    //Serve ad evitare problemi dato che il button è dentro al tr
    event.stopPropagation();

    location.hash=jQuery(this).attr("data-url");
  });
}


//Funzione di creazione di un Survey (si chiamava prima preparaArray)
function createSurveyJSON(id_farm, id_survey_schema){

  var s=partecipa_storage.getSettings();

  var survey={
    guid: tools.getGuid(),
    deviceID:s.deviceID,
    closed:false,
    step:0,
    uid:s.user.uid,
    id_farm: id_farm,
    sync:false,
    timestamp: new Date()
  };

  if (id_survey_schema==99) {
    survey.num_si=0;
    survey.num_no=0;
    survey.perc_infestazione=0;
  }
  else if(id_survey_schema==98){
    survey.fase_fenologica=null;
  }
  else if(id_survey_schema==5){
    survey.catture_trap_1='';
    survey.catture_trap_2='';
    survey.catture_trap_3='';
    survey.media_catture_trap=0;
  }

  return survey;
}






export function getFormattedDate(date,format){
  function addZero(i) {
    if (i < 10) {
        i = "0" + i;
    }
    return i;
  }

  var today=new Date(date);

  var today_yymmdd=new Date(today).getFullYear()+"-"+addZero(new Date(today).getMonth()+1)+"-"+addZero(new Date(today).getDate());
  today=addZero(new Date(today).getDate())+"/"+addZero(new Date(today).getMonth()+1)+"/"+addZero(new Date(today).getFullYear());
  if (typeof format!='undefined') {
    if (format=='dd/mm/yyyy') {
      return today;
    }
    else if (format=='yyyy-mm-dd') {
      return today_yymmdd;
    }
    else {
      alert('Formato non ancora sviluppato.');
      return today_yymmdd;
    }
  }
  else {
    return today_yymmdd;
  }
}

export function getDistanceFromLatLonInKm([lat1,lon1],[lat2,lon2]) {
  var R = 6371; // Radius of the earth in km
  var dLat = deg2rad(lat2-lat1);  // deg2rad below
  var dLon = deg2rad(lon2-lon1);
  var a =
    Math.sin(dLat/2) * Math.sin(dLat/2) +
    Math.cos(deg2rad(lat1)) * Math.cos(deg2rad(lat2)) *
    Math.sin(dLon/2) * Math.sin(dLon/2)
    ;
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  return R * c; // Distance in km
}

function deg2rad(deg) {
  return deg * (Math.PI/180);
}


export function validateEmail(email) {
  var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
}

// Differenza in giorni tra 2 timestamp
function daysDifference(timestamp1, timestamp2) {

    var difference = timestamp1 - timestamp2;
    return Math.floor(difference/1000/60/60/24);

}

export function calcolo_mosca(si, no){
  var totale=si+no;
  if(totale>0){
    var percentuale=si/totale;
    return Math.round(percentuale*100)+'%';
  }
  else{
    return "-";
  }
}

//TODO ci sono fasi mancanti (almeno per l'Abruzzo)
export function getFaseFenologica(fase_num){
  if (fase_num=='fase_10') {
    return "Riposo vegetativo";
  }
  else if (fase_num=='fase_11') {
    return "Sviluppo foglie";
  }
  else if (fase_num=='fase_15') {
    return "Ripresa vegetativa";
  }
  else if (fase_num=='fase_20') {
    return "Ripresa vegetativa";
  }
  else if (fase_num=='fase_39') {
    return "Sviluppo bottoni fiorali";
  }
  else if (fase_num=='fase_52') {
    return "Sviluppo bottoni fiorali";
  }
  else if (fase_num=='fase_55') {
    return "Mignolatura piena";
  }
  else if (fase_num=='fase_61') {
    return "Inizio fioritura";
  }
  else if (fase_num=='fase_65') {
    return "Fioritura";
  }
  else if (fase_num=='fase_69') {
    return "Allegagione";
  }
  else if (fase_num=='fase_70') {
    return "Indurimento nocciolo";
  }
  else if (fase_num=='fase_75') {
    return "Indurimento nocciolo";
  }
  else if (fase_num=='fase_81') {
    return "Inizio invariatura";
  }
  else if (fase_num=='fase_89') {
    return "Maturazione";
  }
  else {
    return "---";
  }
}
