/*jshint esversion: 6 */

import global from '../../global.js';
import storage from '../../storage.js';
import t from '../../translate.js';

import Framework7 from 'framework7/framework7.esm.bundle.js';
// Import F7 Styles
import 'framework7/css/framework7.bundle.css';
import 'framework7-icons';
import './f7.css';

//import 'material-design-iconic-font/dist/css/material-design-iconic-font.css';

export function init(param){

  var sidebars=param.sidebars;
  //Crea l'app f7
  var html='';
  html+='<div class="statusbar"></div>';

  html+='<div class="panel panel-left panel-reveal panel-init">';
    html+='<div class="page">';
      html+='<div class="page-content">';

        if (param.doLogin!==false) {
          html+='<div class="list links-list">';
            html+='<ul>';
            var username='';
            if (param.username) {
              username=param.username;
            }

            if (param.uid && param.uid>0) {
              html+='<li><a class="link external icon-only" href="#my_profile"><i class="f7-icons">person_crop_circle</i><span>'+t('Ciao')+' '+username+'</span></a></li>';
              // Effettua Logout
            }
            else {
              html+='<li><a class="link external icon-only" href="#login"><i class="f7-icons">square_arrow_right</i><span>'+t('Accedi')+'</span></a></li>';
              // Effettua Login
            }
            html+='</ul>';
          html+='</div>';
        }

        html+='<div id="sidebar_content"></div>';

        if (param.doLogin!==false) {
          html+='<div class="list links-list">';
            html+='<ul>';
            if (param.uid && param.uid>0) {
              html+='<li><a class="link external icon-only" href="#logout"><i class="f7-icons">square_arrow_left</i><span>'+t('Esci')+'</span></a></li>';
              // Effettua Logout
            }
            html+='</ul>';
          html+='</div>';
        }

        html+='<div style="font-size: 14px;" class="list simple-list">';
          html+='<ul id="version"></ul>';
        html+='</div>';

      html+='</div>';
    html+='</div>';
    html+='<div class="panel-resize-handler"></div>';
  html+='</div>';
  // html+='<div class="panel panel-right panel-cover theme-dark"><div class="view view-init" data-url="/panel-right/"></div></div>';

  //Inizio del content
  html+='<div  class="view view-main view-init safe-areas" data-url="/">';

    //Inizio della pagina
    html+='<div data-name="home" class="page">';

      //Navbar alta
      html+='<div class="navbar"><div class="navbar-bg"></div><div class="navbar-inner sliding">';
      var showLeftMenu=true;
        if (typeof param.showLeftMenu!=='undefined' && param.showLeftMenu===false) {
          showLeftMenu=false;
        }

        html+='<div id="ae_navbar_left" class="left">';
        if (showLeftMenu) {
          html+='<a href="#" class="link icon-only panel-open" data-panel="left"><i class="f7-icons">line_horizontal_3</i></a>';
        }
        html+='</div>';

        html+='<div class="title">'+param.title+'</div>';

        html+='<div id="ae_navbar_right" class="right">';
          // html+='<div id="sync_status">.</div>';
        html+='</div>';
      html+='</div></div>';

      //Navbar bassa
      html+='<div class="toolbar toolbar-bottom">';
        html+='<div id="footer" class="toolbar-inner">';
        html+='</div>';
      html+='</div>';

      //Contenuto
      var addclass = "";
      if( param.ptr ) {
        addclass += "ptr-content";
      }
      html+='<div id="f7" class="page-content '+addclass+'">';
        html+='<div class="ptr-preloader">';
          html+='<div class="preloader"></div>';
          html+='<div class="ptr-arrow"></div>';
        html+='</div>';

        html+='<div id="f7_content">';
          html+='<p></p>';
        html+='</div>';

        // html+='<div id="snackbar"><a id="reload">Nuova versione disponibile. Clicca qui per aggiornare.</a></div>'
        html+='<div style="display:none;" id="snackbar" class="fab fab-extended fab-center-bottom">';
          html+='<a id="reload"';
            html+='<i class="f7-icons">cloud_download_fill</i>';
            html+='<div class="fab-text">Aggiorna l\'app</div>';
          html+='</a>';
        html+='</div>';

        html+='<div style="display:none;" id="download_app" class="fab fab-extended fab-center-bottom update_app">';
          html+='<a>';
            html+='<i class="f7-icons">cloud_download_fill</i>';
            html+='<div class="fab-text">Aggiorna l\'app</div>';
          html+='</a>';
        html+='</div>';

      html+='</div>';

     //Fine della pagina
     html+='</div>';

  //Fine del content
  html+='</div>';

  document.getElementById(param.div_id).innerHTML=html;

  var sidebar='';
  sidebar+='<div class="list links-list">';
    sidebar+='<ul id="left-sidebar-navigation">';

      //se è settato un oggetto sidebar la aggiunge all'app
      if(typeof sidebars!='undefined'){
        for(var i=0; i<sidebars.length; i++){
          var side=sidebars[i];
          console.log(side.label);
          if (param.uid && param.uid>0 && side.label=='Login') {
            sidebar+='<li>';
              sidebar+='<a class="link external icon-only" href="#logout" ><i class="f7-icons">lock</i><span>Logout</span></a>';
            sidebar+='</li>';
          }
          else if(side.html){
            sidebar+='</ul></div>';
            sidebar+=side.html;
            sidebar+='<div class="list links-list"><ul>';
          }
          else {
            sidebar+='<li>';
              var className='';
              if (side.class) {
                className=side.class
              }
              sidebar+='<a class="link external icon-only '+className+'"  href="'+side.href+'" ><i class="f7-icons">'+side.icon_f7+'</i><span>'+side.label+'</span></a>';
            sidebar+='</li>';
          }
        }
      }
      else {
        sidebar+='<li class="selected">';
          sidebar+='<a href="#" class="link external"><i class="f7-icons">house_alt</i> Home</a>';
        sidebar+='</li>';
      }

    sidebar+='</ul>';
  sidebar+='</div>';

  jQuery('#sidebar_content').html(sidebar);


  var version_number='PWA';
  var lastRelease='';
  var s=storage.get();
  console.log(global.app_version);
  console.log(s.version);
  if (s.version) {
    version_number=s.version;
  }
  
  if(global.app_version){
    version_number=global.app_version;
  }

  if (s.release_date) {
    lastRelease=s.release_date;
  }

  var version='';
  version+='<li id="sidebar_aggiorna_app" class="update_app" style="display:none">';
    version+='<i style="color:#8e8e93;" class="f7-icons">cloud_download</i>Aggiorna l\'app';
  version+='</li>';
  version+='<li>';
    version+=t('Versione')+': '+version_number;
    version+='<div class="item-footer">'+lastRelease+'</div>';
  version+='</li>';
  jQuery('#version').html(version);

  jQuery(".update_app").click(function() {
    location.href=global.download_app_path;
  });

  jQuery(".link").click(function() {
    closeleftpanel();
  });

  const app = new Framework7({
    root: '#'+param.div_id,
    id: 'io.framework7.testapp', // App bundle ID
    name: 'Framework7', // App name
    theme: 'ios', // Automatic theme detection
    iosDynamicNavbar: true,
    touch: {
      fastClicks: true,
    }
  });

  function closeleftpanel(){
    // debugger
    if(app.panel.allowOpen===true){
      app.panel.close('left',true);
    }
  }

  global.app=app;
}

export function render(page){
  var title='';
  if(page.title){
    title=page.title;
  }
  var content='';
  if(page.content){
    content=page.content;
  }

  var card=true;
  var html='';
  if(page.exclude_card){
    card=false;
  }
  if(card){
    html+='<div class="card">';
      if (title!=='') {
        html+='<div class="card-header">'+title+'</div>';
      }
      html+='<div class="card-content card-content-padding">'+content+'</div>';
    html+='</div>';
  }
  else{
    if (title!='') {
      html+='<div class="block-title block-title-medium">'+title+'</div>';
    }
    html+='<p>'+content+'</p>';
  }

  document.getElementById('f7_content').innerHTML=html;
}
